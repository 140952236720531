body[data-page="poetry"]{
    .title-page {
		@include mobile {
			font-size: 2.3rem;
		}
    }
	.background-wrapper {
		&:after {
			background-color: rgba(0,0,0,0.7);
			@include mobile {
				background-color: rgba(0,0,0,0.7);
			}
		}
		&.open::after {
			background: rgba(0, 0, 0, 0.8) !important;
		}
	}
	.text-top-page-mobile, .text-top-page {
		display: none !important;
	}
}

#poets {
	width: 100%;
	display: flex;
	background: #f3dfc1;
	position: relative;
	padding: 1rem;
	@include mobile {
		flex-direction: column;

		#content-poetry {
			order: 1;
		}
		#col-left-poets {
			order: 2;
		}
		#col-right-poets {
			order: 3;
		}
	}
	
	#col-left-poets, #col-right-poets {
		width: 15%;
		padding: 1rem;
		padding-bottom: 0;
		@include minidesktop {
			padding: 0;
		}
		@include mobile {
			width: 100%;
			padding: 1rem;
			padding-top: 0;
		}

		.title-list {
			font-family: 'IM Fell English SC', serif;
			font-size: 1.4rem;
			text-align: center;
			padding-top: 1rem;
		}
		.list {
			margin-top: 1rem;
			font-variant: small-caps;
			list-style: none;
			padding-left: 0.5rem;
			@include mobile {
				width: 60%;
				margin: auto;
				padding-top: 1rem;
				line-height: 100%;
				padding-left: 0;
			}
			.poem {
				padding-bottom: 0.5rem;
				@include mobile {
					padding-top: 0.8rem;
				}
			}
			.army-poem {
				padding-bottom: 0;
			}
		}
		.poem {
			display: flex;
			align-items: center;
		}
		.icon-puce {
			margin-top: 0;
		}
		.puce {
			width: 3rem;
			margin-right: 0.5rem;
		}
		.army {
			width: 1.4rem;
		}
	}
	#col-left-poets {
		@include mobile {
			padding-bottom: 2rem;
		}
	}
	#col-right-poets {
		.list {
			font-family: 'newpaper';
			font-variant: normal;
		}
	}
	#content-poetry {
		width: 70%;
		background: #f3dfc1;
		position: relative;
		z-index: 1;
		margin: 0;
		padding-top: 1rem;
		@include minidesktop {
			padding-top: 0;
		};
		@include mobile {
			width: 100%;
			padding-top: 0;
		}
		#poetry {
			position: relative;
			width: 100%;
			margin: auto;
			background: #f3dfc1;
			padding-left: 1rem;
			padding-right: 1rem;
			@include minidesktop {
				padding-left: 1rem;
				padding-right: 1rem;
			}
			@include mobile {
				padding-left: 0;
				padding-right: 0;
			}
			.title-poets {
				display: block;
				width: 50%;
				position: relative;
				font-family: "newpaper-italic";
				font-size: 1.7rem;
				padding-top: 0;
				padding-bottom: 1rem;
				@include minidesktop {
					padding-top: 0;
					padding-bottom: 0;
				}
				@include mobile {
					width: 100%;
					padding-top: 0;
				}
			}
			.content {
				width: 100%;
				display: flex;
				margin: auto;
				@include mobile {
					display: block;
				}
			}
			.text-part {
				display: block;
				margin-right: auto;
				width: 55%;
				@include minidesktop {
					width: 100%;
				}
				@include mobile {
					width: 100%;
				}
			}
			.citation {
				position: absolute;
				font-family: 'IM Fell English SC', serif;
				width: 73%;
				line-height: 60px;
				margin-top: 0.5rem;
				font-size: 4rem;
				z-index: 2;
				line-height: 80%;
				@include minidesktop {
					font-size: 3rem;
				}
				@include mobile {
					font-size: 3rem;
					position: relative;
					line-height: 80%;
					width: 100%;
					br {
						display: none;
					}
				}
			}
			.text {
				text-align: justify;
				color: #584746; //tons marrons clairs
				z-index: 1;
				width: 100%;
				margin-top: 9rem;
				display: flex;
				gap: 0.5rem;
				@include minidesktop {
					margin-top: 7rem;
					width: 98%;
				}
				@include mobile {
					display: block;
					margin-top: 0;
					gap: unset;
					margin-top: 1rem;
				}
				.size-text {
					width: 50%;
					@include minidesktop {
						width: 50%;
					}
					@include mobile {
						width: 100% !important;
						margin-top: -0.5rem;
					}
				}
				.short {
					width: 50%;
					@include minidesktop {
						width: 50%;
					}
					@include mobile {
						width: 100% !important;
					}
				}
				i {
					font-size: 1.5rem;
					font-family: "handwriter";
					font-weight: 900;
					margin-top: -2rem;
					margin-left: 1rem;
					@include mobile {
						margin-left: 0;
						line-height: 100%;
					}
				}
			}
			.img-part {
				display: block;
				margin: auto;
				width: 45%;
				position: relative;
				margin-top: 0;
				@include mobile {
					width: 100%;
					margin-top: 1rem;
				}
				@include minidesktop {
					display: none;
				}
				.img {
					width: 100%;
					margin-left: auto;
					margin-right: auto;
					display: block;
					margin-top: 4rem;
					padding-left: 3rem;
					@include minidesktop {
						display: none;
					}
					@include mobile {
						width: 100%;
						margin-top: 1rem;
						padding-left: 0;
						margin-bottom: 1rem;
					}
				}
			}
			b {
				font-style: normal !important;
				font-size: 3.7rem;
				color: #f3dfc1;
			}
		}
		.poems-section {
			display: flex;
			margin-bottom: 2rem;
			@include minidesktop {
				margin-bottom: 1rem;
			}
			@include mobile {
				display: block;
				margin-top: 0;
				margin-bottom: 2rem;
			}
			.title-poets {
				display: block;
				width: 50%;
				position: relative;
				font-family: "newpaper-italic";
				font-size: 1.7rem;
				@include mobile {
					width: 100%;
					margin-top: 2rem;
				}
			}
			.search {
				width: 50%;
				margin-left: auto;
				margin-right: 1rem;
				text-align: right;
				@include mobile {
					width: 100%;
				}
			}
		}
		#poets {
			width: 100%;
			display: block;
			margin-top: 0;
			@include minidesktop {
				padding: 0;
				margin-top: 0;
			}
			.img-separator {
				@include mobile {
					padding-top: 2rem;
				}
			}
			.poets-section {
				width: 100%;
				display: none;
				margin: auto;
				padding-bottom: 1.5rem;
				padding-left: 0;
				list-style: none;
				flex-wrap: wrap;
				&.active {
					display: flex;
					@include minidesktop {
						display: grid;
						grid-template-columns: repeat(3, 1fr);
					}
					@include mobile {
						display: flex;
						grid-template-columns: inherit;
					}
				}
				@include minidesktop {
					padding-bottom: 0;
				}
				@include mobile {
					flex-wrap: wrap;
					padding-bottom: 0;
				}

				li {
					width: 25%;
					margin-bottom: 1.5rem;
					@include minidesktop {
						width: 100%;
					}
					@include mobile {
						width: 50%;
						margin-bottom: 0;
					}
				}
				.poem {
					margin: auto;
					margin-left: 0.5rem;
					padding-right: 1rem;
					@include mobile {
						padding: 0;
						margin-left: auto;
					}
				}
				.poems {
					display: flex;
					margin: auto;
					@include minidesktop {
						display: -webkit-inline-box;
					}
					@include mobile {
						flex-direction: column;
						justify-content: center;
						align-items: center;
						text-align: center;
						display: flex;
					}
				}
				.circle {
					width : 100px;
					height : 100px;
					border: none;
					-moz-border-radius : 75px;
					-webkit-border-radius : 75px;
					border-radius : 75px;
					@include minidesktop {
						width : 70px;
						height : 70px;
					}
					@include mobile {
						width: 100px;
						height: 100px;
					}
				}
				.author {
					font-family: "old";
					padding-bottom: 0.5rem;
					@include minidesktop {
						padding-bottom: 0;
					}
					@include mobile {
						padding-bottom: 0;
						padding-top: 0.5rem;
						font-size: 0.9rem;
					}
				}
				.title-poem {
					font-family: "newpaper-italic";
					color : #584746;
					line-height: 130%;
					@include mobile {
						padding-bottom: 1.5rem;
					}
				}
			}
		}
	}
}

.img-sep {
	width: 80%;
	margin: 3rem auto;
	display: block;
	transform: rotate(360deg);
	margin-top: 2rem;
}

.poets-navigation {
	width: 100%;
	margin: auto;
	display: flex;
	justify-content: space-between;

	@include mobile {
		width: 90%;
	}

	button, a {
		visibility: hidden;

		&.active {
			visibility: visible;
		}
	}
	.next-poets {
		text-align: right;
		color: black;
		@include minidesktop {
			padding-top: 0;
		}
		@include mobile {
			width: 100%;
			padding-top: 2rem;
		}
	}
	.prev-poets {
		text-align: left;
		@include mobile {
			width: 100%;
			padding-top: 2rem
		}
	}
    a {
        border: none;
        background: none;
        text-decoration: underline;
        font-family: 'IM Fell English SC', serif;
        font-variant: small-caps;
        cursor: pointer;
    }
}

button{
    border: none;
    background: none;
    text-decoration: underline;
    font-family: 'IM Fell English SC', serif;
    font-variant: small-caps;
    cursor: pointer;
}


 #footer {
	padding-top: 1rem;
	@include mobile {
		padding-top: 2rem;
	}
 }