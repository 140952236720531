body[data-page="founder"]{
    .title-page {
		@include mobile {
			font-size: 2.3rem;
		}
    }
	.icon-left {
		width: 6rem !important;
	}
	.img-separator {
		width: 60%;
		margin: auto;
		@include mobile {
			margin-top: 2rem;
			margin-bottom: 2rem;
		}
	}
	.title-home {
		@include mobile {
			top: 45% !important;
		}
	}
	.text-top-page-mobile, .text-top-page {
		display: none !important;
	}
}

@mixin minidesktop {
	@media screen and (max-height: 900px) {
		@content;
	}
}

.display-none-minidesktop {
	@include minidesktop {
		display: none !important;
	}
}

#jprosnay-biography {
	position: relative;
	display: block;
	width: 73%;
	margin: auto;
	padding-top: 2rem;
	padding-bottom: 2rem;
	margin-top: 2rem;
	@include minidesktop {
		width: 95%;
	}
	@include mobile {
		width: 90% !important;
		margin-top: 0;
	}
	.title {
		font-family: "newpaper-italic";
		font-size: 1.7rem;
		@include minidesktop {
			margin-left: 0;
		}
		@include mobile {
			padding-top: 1rem;
		}
	}
	.presentation {
		display: flex;
		width: 100%;
	}
	b {
		font-style: normal !important;
		font-size: 3.7rem;
		color: #f3dfc1;
	}
	.img {
		width: 90%;
		z-index: 1;
		position: relative;
		filter: grayscale(50%);
		&:before {
			background: rgba(0, 0, 0, 0.6);
			position: absolute;
			height: 100%;
			width: 100%;
			content: "";
			top: 0;
			left: 0;
			z-index: 1000;
		}
	}
	.img-part {
		display: block;
		margin-left: auto;
		margin-right: 0;
		width: 50%;
		position: relative;
		@include minidesktop {
			width: 45%;
			margin-left: 0;
			margin-right: auto;
		}
		@include mobile {
			width: 100%;
			margin: auto;
		}
		.img-founder {
			width: 28rem;
			margin: auto;
			display: block;
			@include minidesktop {
				margin-top: 2rem;
			}
			@include mobile {
				margin: auto;
				display: block;
				width: 100%;
				margin-bottom: 1rem;
				margin-top: 1rem;
			}
		}
	}
	.text-part {
		display: block;
		margin-right: auto;
		width: 55%;
		@include minidesktop {
			margin: auto;
		}
		@include mobile {
			width: 100%;
		}
	}
	.citation {
		position: absolute;
		font-family: 'IM Fell English SC', serif;
		top: 0;
		line-height: 80%;
		font-size: 4rem;
		z-index: 2;
		width: 55%;
		right: 2rem;
		@include minidesktop {
			width: 55%;
			right: 2rem;
		}
		@include mobile {
			position: relative;
			margin: auto;
			display: block;
			font-size: 3.5rem;
			line-height: 75%;
			margin-bottom: 1rem;
			width: 100%;
			right: unset;
		}
	}
	.text {
		text-align: justify;
		color: #584746;
		z-index: 1;
		width: 70%;
		margin-top: 10rem;
		@include minidesktop {
			margin-top: 4rem;
		}
		i {
			font-size: 1.5rem;
			font-family: "handwriter";
			font-weight: 900;
			line-height: 100%;
			font-style: normal;
		}
	}
	span {
		display: block;
		margin-top: -0.5rem;
	}
	.read-more {
		margin-top: 0;
		color: black;
		font-family: 'IM Fell English SC', serif;
		font-weight: 900;
		text-align: right;
		display: flex;
		.text {
			margin-top: 1rem;
			margin-left: 0;
			margin-right: 0;
			text-align: right;
			width: 5rem;
		}
		img {
			margin-top: 0.9rem;
			width: 1.5rem;
			margin-left: auto;
			margin-right: 0;
		}
	}
}

#biography {
    background: #f3dfc1;
    z-index: 1;
    position: relative;
	display: flex;
	padding-bottom: 2rem;
	line-height: normal;
	@include mobile {
		display: block;
	}
	#jprosnay-biography {
		width: 85%;
		padding-top:0;
		@include minidesktop {
			width: 100%;
		}
	}
	.presentation {
		width: 100%;
		margin:auto;
		@include minidesktop {
			width: 95%;
		}
		@include mobile {
			display: block;
			width: 100%;
		}
	}
	.title {
		margin-left: 2rem;
		text-align: left;
		@include mobile {
			margin-left: 0;
			margin-top: 0;
		}
	}
	.img-sep {
		width: 80%;
		margin: 3rem auto;
		display: block;
		transform: rotate(360deg);
		margin-bottom: 0 !important;
		img {
			margin-bottom: 0 !important;
		}
	}
	.img-part {
		width: 45%;
		@include minidesktop {
			width: 50%;
		}
		@include mobile {
			width: 100%;
		}
	}
	.text-part {
		width: 50%;
		@include minidesktop {
			width: 55%;
		}
		@include mobile {
			width: 100%;
		}
	}
	.img {
		width: 100%;
	}
	.all-text {
		display: flex;
		width: 100%;
		@include minidesktop {
			width: 90%;
		}
		@include mobile {
			width: 100%;
		}
	}
	.title-poem {
		font-family: 'IM Fell English SC', serif;
		text-align: center;
		font-size: 2rem;
		line-height: 100%;;
		@include mobile {
			font-size: 2.5rem;
			width: 85%;
			margin: auto;
			line-height: 90%;
		}
	}
	.text-poem {
		display: block;
		margin: 2rem 1rem;
        font-size: 1rem;
		color: #312e2e;
		@include minidesktop {
			margin-top: 1.5rem;
		}
		@include mobile {
			font-size: 1rem;
			margin-top: 2rem;
			margin-left:2rem;
			color: #584746;
		}
	}
	.author {
		font-family: "handwriter2";
		font-size: 1.8rem;
		text-align: right;
		margin-right: 2rem;
	}
	.text {
		margin-top: 9rem;
		width: 100%;
		@include minidesktop {
			margin-top: 8rem;
		}
		@include mobile {
			margin-top: 0;
			width: 100%;
		}
	}
}

.poem-right {
	width: 15%;
	margin-left: auto;
	margin-right: 0;
	margin-top: 2rem;
	border-left: 1px solid black;
	@include minidesktop {
		display: none;
	}
	@include mobile {
		display: block;
		width: 100%;
		border: none;
		margin-top: 1.5rem;
	}
}
.underline-title {
	display: block;
	width: 12rem;
	height: 2rem;
	transform: scaleX(-1);
	margin: 1rem auto;
}

.founder-sep {
	width: 70%;
	margin-top: 3rem;
	margin-bottom: 1rem;
	margin-left: auto;
	margin-right: auto;
	transform: rotate(0deg);
}

.medias-sep {
	width: 100%;
	margin-top: 1rem;
	margin-bottom: 1rem;
	margin-left: auto;
	margin-right: auto;
	transform: rotate(0deg);
	.img-medias-sep {
		width: 100% !important;
		margin-bottom: 1rem !important;
		height: 1.5rem !important;
	}
}

.img-sep {
	.img {
		width: 60%;
		margin-left: auto;
		margin-top: 2rem;
		display: block;
		transform: rotate(180deg);
	}
}

#quote-end, #quote-founder {
	position: relative;
	height: 100vh;
	&:before {
		background: rgba(0, 0, 0, 0.8) !important;
		position: absolute;
		height: 100%;
		width: 100%;
		content: "";
		top: 0;
		left: 0;
		z-index: 1;
		@include mobile {
			background: rgba(0, 0, 0, 0.8) !important;
		}
	}
	.quote {
		color:  #f8ead5;
		font-size: 6rem;
		font-family: "handwriter2";
		position: absolute;
		transform: translate(-50%, -50%);
		top: 50%;
		left: 50%;
		z-index: 1;
		width: 70%;
		line-height: 80%;
		text-align: center;
		@include minidesktop {
			font-size: 5rem;
		}
		@include mobile {
			width: 90%;
			margin: auto;
			font-size: 3.5rem !important;
		}
	}
}

#jprosnay {
	background: #f3dfc1;
	display: flex;
	line-height: normal;
	position: relative;
	margin: auto;
    padding-bottom: 2rem;
	z-index: 1;
	width: 100%;
	@include mobile {
		display: block;
	}
	.poem-left {
		width: 20%;
		margin-left: 0;
		margin-right: auto;
		margin-top: 2rem;
		border-right: 1px solid black;
		@include minidesktop {
			display: none;
		}
		@include mobile {
			width: 100%;
			margin-top: 0;
			padding-top: 2rem;
			border:none;
			display: block;
		}
	}
	.title-poem {
		font-family: 'IM Fell English SC', serif;
		text-align: center;
		font-size: 2rem;
		background-color: #312e2e;
		color: #f8ead5;
		padding: 0.3rem;
		width: 85%;
		margin: auto;
		line-height: 100%;
		@include minidesktop {
			font-size: 1.8rem;
			padding: 0.7rem;
		}
		@include mobile {
			font-size: 2rem;
			width: 90%;
		}
	}
	.img {
		display: block;
		width: 12rem;
		height: 1rem;
		transform: scaleX(-1);
		margin: 1rem auto;
	}
	.text {
		display: block;
		margin: 2rem 2rem;
        font-size: 0.95rem;
		color: #584746;
		@include mobile {
			font-size: 0.9rem;
		}
		@include mobile {
			margin: 2rem auto;
    		width: 90%;
			font-size: 1rem;
		}
	}
	.author {
		font-family: "handwriter2";
		font-size: 1.8rem;
		text-align: right;
		margin-right: 2rem;
	}
	.medias {
		width: 80%;
		margin: auto;
		margin-top: 0;
		@include minidesktop {
			width: 95%;
		}
		@include mobile {
			width: 90%;
		}
		.read-more {
			width: 45%;
			@include mobile  {
				width: 80%;
				font-size: 1.1rem;
			}
		}
		.back-desktop {
			margin-bottom: 1rem;
			margin-top: 1rem;
			@include minidesktop {
				margin-bottom: 0;
			}
		}
	}
	.title-medias {
		font-family: 'IM Fell English SC', serif;
		text-align: center;
		font-size: 2rem;
		margin-bottom: 2rem;
	}
	span {
		text-align: center;
		margin: auto;
		display: block;
	}
	#svg-titre {
		width: 100%;
		margin-top: -2rem;
		@include minidesktop {
			margin-top: -1.5rem;
		}
		@include mobile {
			display: none;
		}
		.back-desktop,.back {
			background: #f3dfc1;
		}
		path {
			fill: transparent;
			background-color: #333;
		}
		.big-title {
			margin: auto;
			font-size: 1.3rem;
			text-decoration: underline;
			font-family: 'IM Fell English SC', serif;
		}
		.big-subtitle {
			font-size: 9px;
			font-family: "handwriter";
			margin: auto;
		}
	}
	.title-mobile {
		display: none;
		@include mobile {
			display: block;
			margin: auto;
			font-size: 3rem;
			line-height: 80%;
			text-decoration: underline;
			font-family: 'IM Fell English SC', serif;
			text-align: center;
		}
	}
    #press {
        margin-top: -2rem;
		margin-bottom: 2rem;
		@include minidesktop {
			margin-bottom: 0;
		}
		@include mobile {
			margin-top: 1rem;
		}
    }
	#press, #video {
		display: flex;
		@include mobile {
			display: block;
		}
		.img-left {
			width: 50%;
			@include minidesktop {
				margin: auto;
				margin-top: 1rem;
			}
			@include mobile {
				width: 100%;
				margin-top: 0;
			}
		}
		.img-press {
			width: 60%;
			margin: auto;
			display: block;
			@include minidesktop {
				width: 90%;
				margin-top: 1rem;
			}
			@include mobile {
				width: 100%;
			}
		}
		.block-press {
			width: 50%;
			margin: auto;
			margin-top: 2rem;
			@include minidesktop {
				margin: auto;
			}
			@include mobile {
				margin-top: 2rem;
				width: 100%;
			}
			.extract {
				width: 80%;
				margin: 2rem auto;
				text-align: justify;
				color: #584746;
				@include mobile {
					width: 100%;
					margin: 1rem auto;
					margin-bottom: 2rem;
				}
			}
			a {
				color: black;
				margin: auto;
				text-align: center;
				width: 80%;
				font-size: 1.1rem;
				@include minidesktop {
					width: 50%;
					margin-top: 2rem;
				}
				@include mobile {
					width: 65%;
				}
			}
			i {
				font-family: "handwriter2";
				font-style: normal;
				font-size: 2rem;
			}
		}
	}
	#video {
		.extract {
			width: 70%;
			padding-left: 2rem;
			@include mobile {
				padding-left: 0;
			}
		}
	}
	.title {
		font-family: 'IM Fell English SC', serif;
		text-align: center;
		font-size: 1.5rem;
		margin-top: 0;
		text-decoration: underline;
		@include mobile {
			margin-top: 2rem;
		}
	}
	#audio {
		display: flex;
		margin: 0 auto;
		@include mobile {
			display: none;
		}
		.img-right {
			width: 50%;
			@include mobile {
				width: 100%;
			}
		}
		.bloc-audio {
			.title {
				margin-top: 0;
			}
		}
		.img-press {
			width: 75%;
			margin: auto;
			display: block;
			@include minidesktop {
				width: 75%;
			}
			@include mobile {
				width: 100%;
			}
		}
		.block-audio {
			width: 50%;
			margin: auto;
			@include mobile {
				width: 100%;
			}
			.extract {
				width: 80%;
				margin: 2rem auto;
				text-align: justify;
				color:#584746;
			}
		}
	}
	#audio-mobile {
		display: none;
		@include mobile {
			display: block;
		}
		.img-right {
			width: 50%;
			@include mobile {
				width: 100%;
			}
		}
		.img-press-mobile {
			width: 75%;
			margin: auto;
			display: block;
			@include mobile {
				width: 100%;
			}
		}
		.block-audio {
			width: 50%;
			@include mobile {
				width: 100%;
			}
			.extract {
				width: 80%;
				margin: 2rem auto;
				text-align: justify;
				color: #584746;
				@include mobile {
					width: 100%;
					margin: 1rem auto;
					margin-bottom: 2rem;
				}
			}
		}
	}
}


#quote-founder {
	position: relative;
	height: 100vh;
	&:before {
		background: rgba(0, 0, 0, 0.6);
		position: absolute;
		height: 100%;
		width: 100%;
		content: "";
		top: 0;
		left: 0;
		z-index: 1;
	}
	&:after {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
	.fond {
		background-position: 100% 0%;
		background-size: cover;
		position: relative;
		height: 100vh;
		@media screen and (max-width: 768px) {
			background-position: calc(100vw - 80%);
		}
	}
	.title {
		position: absolute;
		transform: translate(-50%,-50%);
		top: 35%;
		left: 50%;
		color:  #f8ead5;;
		font-size: 5rem;
		z-index: 1000;
		font-family: "handwriter";
		font-weight: 700;

		@media screen and (max-width: 768px) {
			font-size: 1.8rem;
			text-align: center;
			width: 90%;
			line-height: 105%
		}
	}
	.quote {
		@include mobile {
			font-size: 3.5rem !important;
			width: 75%;
		}
	}
}



#ina {
	background: #f3dfc1;
	position: relative;
	display: flex;
	.desktop {
		display: flex;
		@include minidesktop {
			display: flex;
		}
		@include mobile {
			display: none;
		}
	}
	.mobile {
		display: none;
		@include minidesktop {
			display: none;
		}
		@include mobile {
			display: flex !important;
		}
	}
	#ina-text {
		margin-left: 0;
		width: 68%;
		@include minidesktop {
			width: 95%;
			margin: auto;
		}
		@include mobile {
			width: 100%;
		}
	}
	.title-ina {
		font-family: "newpaper-italic";
		font-size: 1.7rem;
		padding: 2rem;
		padding-bottom: 1rem;
		@include minidesktop {
			padding-left: 1rem;
		}
	}
	.title-poem {
		font-family: 'IM Fell English SC', serif;
		text-align: center;
		font-size: 2rem;
		color: #312e2e;
		padding: 0.3rem;
		width: 85%;
		margin: auto;
		line-height: 100%;
		@include minidesktop {
			font-size: 1.8rem;
			padding: 0.7rem;
			width: 75%;
		}
		@include mobile {
			font-size: 2rem;
			width: 90%;
		}
	}
	.text-poem {
		color: #312e2e;
		width: 90%;
		margin: auto;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}
	.poem-left {
		width: 16%;
		margin-top: 2rem;
		border-right: 1px solid black;
		@include minidesktop {
			display: none;
		}
	}
	.poem-right {
		width: 16%;
		@include minidesktop {
			display: none;
		}
		.title-poem {
			padding-bottom: 1rem;
		}
	}
	.under-title {
		width: 8rem;
		margin: auto;
		display: block;
		padding-bottom: 0.5rem;
	}
	.author {
		font-family: "handwriter2";
		font-size: 1.8rem;
		text-align: right;
		margin-right: 1rem;
		padding-top: 2rem;
	}
	.citation-block {
		.citation {
			font-family: 'IM Fell English SC', serif;
			width: 100%;
			line-height: 60px;
			font-size: 4rem;
			padding-left: 2rem;
			width: 75%;
			@include minidesktop {
				width: 90%;

			}
			@include mobile {
				width: 100%;
				line-height: 80%;
				font-size: 3rem;
				padding: 1rem;
			}
		}
		b {
			font-style: normal !important;
			font-size: 3.7rem;
			color: #f3dfc1;
		}
	}
	.line {
		margin: auto;
		border-top: 2px solid black;
		margin-top:1.3rem;
		width: 85%;
		display: block;
	}
	.content {
		display: flex;
		@include minidesktop {
			display: flex;
		}
		@include mobile {
			display: block;
		}
	}
	.subtitle {
		color: #584746;
		line-height: 25px;
		margin-left: 2rem;
		padding-bottom: 1rem;
		width: 50%;
		@include mobile  {
			width: 90%;
			text-align: justify;
			line-height: 160%;
			margin-left: 1rem;
			padding-bottom: 0;
		}
		i {
			font-size: 1.8rem;
			font-family: "handwriter";
			font-weight: 900;
			line-height: 100%;
			@include mobile  {
				font-size: 1.3rem;
				line-height: 0;
			}
		}
		b {
			font-family: "newpaper-bold";
			font-weight: 900;
			font-style: italic;
		}
	}
	.read-more {
		width: 45%;
		margin-top: 3rem;
		font-size: 1.1rem;
		@include minidesktop {
			width: 45%;
			margin-top: 2rem;
		}
		@include mobile  {
			width: 80%;
			margin-top: 2rem;
			margin-bottom: 0;
		}
	}
	#ina-video {
		display: flex;
		width: 50%;
		@include minidesktop {
			width: 50%;
		}
		@include mobile  {
			display: block;
			width: 100%;
		}
		.video-wrapper {
			margin-left: 2rem;
			width: 90%;
			@include mobile  {
				width: 100%;
				padding: 1rem;
				margin-left: 0;
				padding-bottom: 0;
			}
			video {
				background: black;
				height: 100%;
				width: 100%;
				margin-top: 1rem;
				@include minidesktop {
					height: auto;
				}
				@include mobile {
					height: 90%;
					margin-top: 0;
				}
			}
		}
		.ina-list {
			margin: auto;
			margin-top: -2rem;
			@include minidesktop {
				margin-top: 0;
			}
			@include mobile {
				margin-top: 0;
			}
			.title-list {
				width: 100%;
				margin: auto;
				margin-top: 0;
				text-align: center;
				line-height: 100%;
			}
			.line-title {
				margin: auto;
				width: 45rem;
				display: block;
				margin-top: -3rem;
				@include minidesktop {
					display: none;
				}
				@include mobile  {
					width: 100%;
					margin-top: 0;
					padding: 1rem;
				}
			}
			.line-subtitle {
				margin: auto;
				width: 10rem;
				display: block;
				@include minidesktop {
					width: 7rem;
					padding-bottom: 1rem;
				}
				@include mobile {
					width: 7rem;
				}
			}
			.rotate {
				transform: rotate(180deg);
			}
			.col-list {
				display: flex;
				margin: auto;
				margin-top: 0;
				margin-bottom: 2rem;
				@include minidesktop {
					padding-right: 0.5rem;
					padding-left: 1rem;
					margin-bottom: 0;
				}
				@include mobile  {
					display: block;
					margin-bottom: 0;
				}
			}
			.col-left, .col-center, .col-right {
				display: block;
				margin: auto;
			}
			.col-center {
				margin-top: -6rem;
				@include minidesktop {
					margin-top: -5rem;
				}
				@include mobile  {
					margin-top: 0;
				}
			}
			.col-left {
				@include mobile  {
					margin-top: 0;
				}
			}
			.border {
				border-right: 1px solid black;
				border-left: 1px solid black;
				padding-right: 2rem;
				padding-left: 2rem;
				@include minidesktop {
					padding-right: 1rem;
					padding-left: 1rem;
				}
				@include mobile {
					padding: 0;
					border: none;
				}
			}
			.border-right {
				padding-right: 2rem;
				@include minidesktop {
					padding-right: 0.5rem;
				}
				@include mobile  {
					padding: 0;
				}
			}
			.border-left {
				padding-left: 2rem;
				@include minidesktop {
					padding-left: 1rem;
				}
				@include mobile  {
					padding: 0;
				}
			}
			.year {
				font-family: 'IM Fell English SC', serif;
				text-align: center;
				margin: auto;
				font-size: 2.5rem;
				@include minidesktop {
					font-size: 2rem;
				}
				@include mobile  {
					font-size: 1.8rem;
					margin-top: 1.5rem;
				}
			}
			.hand-link {
				width: 3rem;
				padding-right: 0.5rem;
				@include minidesktop {
					display: none;
				}
				@include mobile {
					display: block;
				}
			}
			.link {
				cursor: pointer;
				display: block;
				padding-bottom: 0.5rem;
				margin: auto;
				text-align: left;
				text-decoration: underline;
				display: flex;
				align-content: center;
				align-items: end;
				@include minidesktop {
					padding-bottom: 1rem;
				}
				@include mobile {
					padding-bottom: 0;
					text-align: center;
					width: 100%;
					padding-bottom: 0.5rem;
					display: flex;
					align-content: center;
					align-items: center;
					margin: auto;
					padding-left: 1rem;
				}
                &.active {
                    font-weight: bold;
                }
			}
			.mask-minidesktop {
				@include minidesktop {
					display: none;
				}
				@include mobile {
					display: flex;
				}
			}
		}
	}
}
