body[data-page="gallery"]{
    .title-page {
		@include mobile {
			font-size: 2.3rem;
		}
    }
	.background-wrapper {
		&:after {
			background-color: rgba(0,0,0,0.75);
			@include mobile {
				background-color: rgba(0,0,0,0.7);
			}
		}
		&.open::after {
			background: rgba(0, 0, 0, 0.8) !important;
		}
	}
	.text-top-page-mobile, .text-top-page {
		display: none !important;
	}
}


.medias {
	position: relative;
	margin: 0 auto -2%;
	background: #f3dfc1;
	z-index: 0;
	width: 100%;
	@include mobile {
		margin: auto;
	}
}

#svg-titre {
	width: 100%;
	margin-top: 0rem;
	@include mobile {
		display: none;
	}
	.back-desktop,.back {
		background: #f3dfc1;
	}
	path {
		fill: transparent;
		background-color: #333;
	}
	.big-title {
		margin: auto;
		font-size: 14px;
		text-decoration: underline;
		font-family: 'IM Fell English SC', serif;
	}
	.big-subtitle {
		font-size: 7px;
		font-family: "handwriter";
		margin: auto;
	}
}

#gallery {
    position: relative;
	background: #f3dfc1;
	@include mobile {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
	#first-line {
		padding-top: 0px;
		margin-top: -1rem;
		@include mobile {
			margin-top: 0rem;
		}
	}
    .line {
        display: flex;
        width: 100%;
        padding: 1rem;
		@include mobile {
			display: block;
			padding: 0;
		}
    }
    .photo-left, .photo-right {
        width: 25%;
        margin: auto;
		margin-top: 0;
		@include mobile {
			width: 100%;
		}
    }
    .photo-center {
        width: 50%;
        margin: auto;
		margin-top: 0;
		margin-top: -3.5rem;
		@include mobile {
			width: 100%;
			margin-top: 0;
		}
    }
    .photo {
        width: 100%;
        margin: auto;
        padding: 1rem;
		padding-bottom: 0;
    }
    .photo-big {
        width: 100%;
        margin: auto;
		margin-top: 0;
        padding: 1rem;
		padding-bottom: 0;
    }
	.legend {
		text-align: center;
		font-size: 1.8rem;
		font-family: "handwriter2";
		@include mobile {
			line-height: 85%;
			width: 90%;
			margin: auto;
		}
	}
}

.videos {
	width: 100%;
	margin: auto;
	@include mobile {
        padding: 1rem;
		padding-bottom: 0;
	}
}
