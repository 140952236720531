body {
	i {
		font-style: normal;
		font-size: 2rem;
		font-family: "handwriter2";
	}
}

body[data-page="publications"]{
    .title-page {
		@include mobile {
			font-size: 2.45rem;
		}
    }
	.img-separator {
		margin: auto;
		margin-top: 1rem;
		margin-bottom: 3rem;
		@include mobile {
			margin-top: 0 !important;
		}
	}	
	.icon-right {
		transform: scaleX(-1);
	}
	.background {
		@include mobile {
			background-position: 70% 50%;
		}
	}
	.img-sep {
		width: 80%;
		margin: 0 auto;
		margin-top: 0;
		margin-bottom: 2rem;
		display: block;
		transform: rotate(360deg);
	}
	.text-top-page-mobile, .text-top-page {
		display: none !important;
	}
}


.background-wrapper {
	position: relative;
	height: 100vh;
	.top-page {
		background: #f3dfc1;
	}
}

#publications1 {
	position: relative;
	background: #f3dfc1;
	display: flex;
	padding: 1.5rem;
	padding-bottom: 0;
	@include mobile {
		display: block;
	}
	.part-left {
		width: 82%;
		margin-top: -1.5rem;
		@include minidesktop {
			width: 75%;
		}
		@include mobile {
			width: 100%;
		}
		.ciel-part {
			width: 100%;
			display: flex;
			@include mobile {
				display: block;
			}
		}
		.title-ciel {
			font-size: 3.8rem;
			font-family: 'old';
			text-align: left;
			display: flex;
			@include minidesktop {
				font-size: 3rem;
				padding-top: 0.5rem;
			}
			@include mobile {
				font-size: 2.3rem;
				margin-top: 0.5rem;
			}
		}
		.title-next {
			margin: auto;
			margin-top: 1rem;
			padding-left: 0.5rem;
			font-family: 'old';
			display: flex;
			width: 90%;
			@include minidesktop {
				width: 100%;
			}
			@include mobile {
				width: 100%;
				border-bottom: 1px solid black;
				border-top: 1px solid black;
				padding-bottom: 0.5rem;
				margin-bottom: 1rem;
				padding-left: 0;
			}
		}
		.mobile {
			display: none;
			@include mobile {
				display: flex !important;
			}
		}
		.date-revue {
			width: auto;
			font-size: 1.6rem;
			margin: auto;
			margin-left: 0;
			margin-bottom: 0;
			@include minidesktop {
				font-size: 1.3rem;
			}
			@include mobile {
				font-size: 1rem;
			}
		}
		.price {
			width: auto;
			font-size: 1.3rem;
			text-align: right;
			margin-right: 1rem;
			margin-left: auto;
			margin-top: 0.5rem;
			@include minidesktop {
				font-size: 1.1rem;
			}
			@include mobile {
				font-size: 0.95rem;
				margin-right: 0;
			}
		}
		.img-part {
			width: 50%;
			margin-right: 1rem;
			.text-block {
				line-height: 150%;
				display: block;
				@include minidesktop {
					width: 100%;
					margin-right: 0;
				}
			}
			@include mobile {
				width: 100%;
			}
			.desktop {
				display: flex;
				@include mobile {
					display: none;
				}
			}
		}
		.flex {
			display: flex;
		}
		.text-part {
			width:50%;
			background: #f3dfc1;
			padding-right: 1rem;
			@include mobile {
				width: 100%;
				padding-right: 0;
			}
		}
		.b-solal {
			width: 90%;
			background: #f3dfc1;
			margin: auto;
			display: block;
			@include minidesktop {
				width: 100%;
			}
			@include mobile {
				width: 100%;
				padding-top: 2rem;
			}
		}
		.signature {
			font-size: 1.5rem;
			font-family: "handwriter";
			text-align: center;
			margin: auto;
			margin-top: -1rem;
			padding-bottom: 2rem;
			display: block;
			@include mobile {
				font-size: 1.2rem;
				margin-top: 0rem;
			}
			i {
				text-decoration: underline;
				@include mobile {
					padding-left: 0.5rem;
					margin-left: auto;
  					margin-right: auto;
				}
			}
		}
		.title-extract {
			border-top: 2px solid black;
			border-bottom: 2px solid black;
			width: auto;
			margin-top: 1.5rem;
			margin-bottom: 1.5rem;
			@include minidesktop {
				margin-top: 1rem;
			}
		}
		.subtitle {
			font-size: 1.2rem;
			@include mobile {
				font-size: 1rem;
			}
		}
		.italic {
			font-style: italic;
			margin-top: 0.5rem;
		}
		.center {
			text-align: left;
			margin: auto;
			margin-top: 0.5rem;
			display: block;
			@include minidesktop {
				width: 55%;
				margin-left: 0;
			}
			@include mobile {
				width: 100%;
			}
		}
		.big-bold {
			font-size: 1.5rem;
			font-weight: 900;
		}
		.text {
			text-align: justify;
			width: 100%;
			float: left;

		}
		.text-block {
			line-height: 150%;
			display: flex;
			@include minidesktop {
				display: block;
			}
			@include mobile {
				display: block;
			}
		}
		.padding-left {
			padding-left: 1rem;
			@include minidesktop {
				padding-left: 0;
			}
			@include mobile {
				padding-left: 0;
				.solal-sign {
					@include mobile {
						display: flex !important;
						align-content: center;
						align-items: baseline;
						width: 85%;
					}
				}
			}
			.mobile {
				display: none;
				@include mobile {
					display: block;
				}
			}
		}
		.end-text {
			width: 100%;
			display: flex;
			@include minidesktop {
				display: block;
			}
			@include mobile {
				display: block;
				margin-top: 0;
			}
		}
		.under-img {
			width: 50%;
			@include mobile {
				width: 100%;
			}
		}
		.enneagramme {
			width: 100%;
			margin: auto;
			display: block;
			margin-top: 1rem;
			@include minidesktop {
				display: none;
			}
			@include mobile {
				display: none;
			}
		}
		.solal-sign {
			display: block;
			@include minidesktop {
				display: none;
			}
			@include mobile {
				display: none;
			}
		}
	}
	.part-right {
		width: 18%;
		border-left: 1px solid black;
		padding-left: 1.5rem;
		@include minidesktop {
			width: 25%;
		}
		@include mobile {
			width: 100%;
			border: none;
			padding-left: 0;
		}
		.block-title {
			border: 2px solid black;
			padding: 0.5rem;
			margin-bottom: 1.5rem;
			text-align: center;
		}
		.title {
			font-variant: small-caps;
			font-family: "old";
			font-size: 1.7rem;
			border-bottom: 2px solid black;
			width: 90%;
			margin: auto;
		}
		.inbook {
			font-family: "newpaper-italic";
			font-size: 0.9rem;
			padding-bottom: 0.2rem;
		}
		.recueil {
			font-size: 1.3rem;
			padding-top: 1rem;
			padding-bottom: 1rem;
			line-height: 120%;
			font-family: "newpaper-bold";
		}
		.date {
			font-family: "newpaper-italic";
			font-size: 0.9rem;
			padding-top: 0.5rem;
		}
		.author {
			font-size: 1.1rem;
		}
		.price {
			text-align: center;
			font-size: 0.9rem;
			font-family: "old";
			padding-top: 1.5rem;
		}
		.text {
			text-align: left;
			line-height: 150%;
			@include minidesktop {
				font-size: 1rem;
			}
			@include mobile {
				font-size: 1rem;
			}
		}
	}
	i {
		font-family: "handwriter2";
		font-style: normal;
		font-size: 2rem;
		line-height: 0%;
	}
}

.separator-mobile {
	width: 14rem;
	margin: auto;
	margin-bottom: 1rem;
}

#quote-published {
	position: relative;
	height: 100vh;
	color:  #f8ead5;
	&:before {
		background: rgba(0, 0, 0, 0.6) !important;
		position: absolute;
		height: 100%;
		width: 100%;
		content: "";
		top: 0;
		left: 0;
		z-index: 1;
	}
	.quote, .title {
		font-size: 2.2rem;
		font-family: 'IM Fell English SC', serif;
		position: absolute;
		transform: translate(-50%, -50%);
		top: 50%;
		left: 50%;
		z-index: 1;
		width: 100%;
		text-align: center;
		line-height: 140%;
		width: 35%;
		@include minidesktop {
			width: 50%;
		}
		@include mobile {
			line-height: 100%;
			font-size: 1.4rem;
			width: 100%;
		}
	}
	.title {
		font-size: 1.2rem;
		top: 33%;
		@include mobile {
			font-size:1.2rem;
			top: 35%;
		}
	}
	b {
		font-weight: normal;
		font-size: 3.7rem;
		font-family: "handwriter2";
		padding-left: 1rem;
	}
	i {
		font-style: normal;
		font-size: 2rem;
		font-family: "handwriter2";
		padding-right: 0.5rem;
		@include mobile {
			font-size: 1.3rem;
		}
	}
	.author {
		font-size: 1.2rem;
		font-family: 'IM Fell English SC', serif;
		z-index: 1;
		width: 100%;
		text-align: right;
		position: absolute;
		transform: translate(-50%, -50%);
		left: 50%;
		padding-right: 25%;
		line-height: 110%;
		top: 67%;
		@include minidesktop {
			top: 67%;
		}
		@include mobile {
			left: 45%;
			top: 65%;
			font-size: 0.9rem;
			padding-right: 0;
			width: 100%;
		}
	}
	.mobile {
		display: none;
		@include mobile {
			display: block;
		}
	}
}

#part-1 {
	position: relative;
	background: #f3dfc1;
	width: 100%;
	.decoration {
		width: 100%;
		.ornement {
			height: 13rem;
			background-image: url("../images/ornement.png");
			background-repeat: repeat-x;
		}
	}
	.title {
		font-size: 5rem;
		font-family: 'IM Fell English SC', serif;
		text-align: center;
		line-height: 50%;
		padding-top: 2rem;
		@include mobile {
			font-size: 3.5rem !important;
			padding-top: 0 !important;
		}
	}
	.subtitle {
		font-size: 2.5rem;
		font-style: italic;
		font-family: 'IM Fell English SC', serif;
		text-align: center;
		padding-bottom: 0.5rem;
		margin-top: -1rem;
		@include minidesktop {
			font-size: 2rem;
		}
		@include mobile {
			font-size: 1.3rem;
			line-height: 85%;
			margin-top: 0.5rem;
			padding-bottom: 1rem;
		}
	}
	.infos {
		border-top: 2px solid black;
		border-bottom: 2px solid black;
		display: flex;
		margin: auto;
		padding: 0.5rem;
		@include mobile {
			width: 100%;
			padding: 0.5rem;
			padding-left: 1rem;
			padding-right: 1rem;
		}
		.price {
			margin: auto;
			margin-right: 1rem;
			width: 100%;
			text-align: right;
			@include mobile {
				width: 25%;
				margin-right: 0;
			}
			i {
				font-family: 'newpaper-italic';
				font-size: 1rem;
				@include mobile {
					font-size: 0.9rem;
				}
			}
			b {
				font-weight: 900;
			}
		}
		.date {
			margin: auto;
			font-size: 1.2rem;
			font-variant: small-caps;
			width: 100%;
			text-align: center;
			@include mobile {
				width: 50%;
				font-size: 1rem;
			}
		}
		.printed {
			margin: auto;
			margin-left: 1rem;
			width: 100%;
			text-align: left;
			@include mobile {
				width: 25%;
				margin-left: 0;
			}
			i {
				@include mobile {
					font-size: 1.2rem;
				}
			}
		}
	}
	#revue-club {
		display: flex;
		width: 100%;
		margin: auto;
		padding-left: 0.5rem;
		//padding-right: 0.5rem;
		@include mobile {
			display: block;
			padding-left: 0;
		}
	}
	#title-revue {
		display: flex;
		padding-top: 0.5rem;
		@include minidesktop {
			padding-top: 1rem;
		}
		@include mobile {
			padding-top: 1rem;
		}
		.title {
			font-size: 5.5rem;
			padding-top: 1rem;
			@include minidesktop {
				font-size: 5rem;
			}
			@include mobile {
				font-size: 5.5rem;
			}
		}
		.title-ornements {
			display: flex;
			margin: auto;
			@include minidesktop {
				justify-items: center;
			}
		}
		.ornement-left, .ornement-right {
			margin-top: 2rem;
			@include mobile {
				display: none;
			}
		}
		.ornement-left {
			margin-right: 5rem;
		}
		.ornement-right {
			margin-left: 5rem;
		}
		.ornement {
			width: 20rem;
		}
	}
	.menu {
		width: 50%;
		margin-bottom: 2rem;
		//border-right: 2px solid black;
		padding-right: 1.5rem;
		@include minidesktop {
			width: 55%;
		}
		@include mobile {
			width: 100%;
			padding-right: 0;
		}
		.summary {
			display: flex;
			margin-top: 1rem;
			@include mobile {
				display: block;
			}
		}
		#hand-left, #hand-right {
			margin: 0 auto;
			.hand {
				width: 8rem;
				margin: auto;
				@include minidesktop {
					width: 5rem;
				}
			}
			@include mobile {
				display: none;
			}
		}
		#hand-right {
  			transform: scaleX(-1);
			margin-right: 0;
		}
		#hand-left {
			margin-left: 0;
		}
		.date {
			font-family: handwriter;
			font-size: 1.4rem;
			margin-bottom: 1rem;
			text-align: center;
			@include minidesktop {
				margin-top: -2rem;
			}
			@include mobile {
				margin-top: -1.4rem;
			}
		}
		.title-summary {
			font-size: 3rem;
			margin-top: auto;
			font-family: 'IM Fell English SC', serif;

			@include mobile {
				width: 100%;
				text-align: center;
				font-size: 2.5rem;
			}
		}
		.legends {
			display: flex;
			width: 100%;
			@include minidesktop {
				margin-top: -1rem;
			}
			@include mobile {
				padding-right: 0.5rem;
				margin-top: 0rem;
			}
			.legend {
				width: 50%;
				font-variant: small-caps;
				margin: auto;
				text-align: right;

				@include mobile {
					width: 100%;
				}
			}

			@include mobile {
				.legend + .legend {
					display: none;
				}
			}
		}
		.hand-mobile {
			display: none;
			@include mobile {
				display: block;
				margin: auto;
			}
			.hand {
				margin: auto;
				display: block;
				width: 8rem;
				@include mobile {
					width: 6rem;
				}

			}
		}
		.list {
			width: 100%;
			display: flex;
			@include mobile {
				display: block;
			}
			.block {
				display: block;
				width: 100%;
			}
			#poems {
				column-count: 2;
				column-gap: 0;
				list-style-type: none;
				margin-top: 0.5rem;
				padding-left: 0;
				width: 100%;
				@include mobile {
					column-count: 1;
					padding-right: 0.5rem;
				}
			}
			.poem {
				display: flex;
				line-height: normal;
				white-space: pre;
				.title-poem {
					font-weight: 700;
					margin-left: 1rem;
					text-align: left;
					font-style: italic;
					@include minidesktop {
						font-size: 0.85rem;
					}
					@include mobile {
						font-size: 0.9rem;
					}
				}
				.separator-points {
					border-bottom: 2px dotted black;
					width: 100%;
					margin: auto;
					margin-bottom: 0.3rem;
				}
				.author {
					font-variant: small-caps;
					margin-right: 1rem;
					text-align: right;
					@include minidesktop {
						font-size: 0.85rem;
						margin-right: 1rem;
					}
					@include mobile {
						font-size: 0.9rem;
						margin-right: 0.8rem;
					}
				}
				.page {
					font-weight: 900;
					@include minidesktop {
						font-size: 0.85rem;
					}
					@include mobile {
						font-size: 0.9rem;
						padding-right: 0.5rem;
					}
				}
			}
		}
	}
	.extract {
		width: 50%;
		margin: auto;
		margin-top: 0;
		@include minidesktop {
			width: 45%;
		}
		@include mobile {
			width: 90%;
		}
		.img {
			width: 90%;
			margin: auto;
			display: block;
			padding: 2rem;
			padding-top: 1rem;
			@include minidesktop {
				padding-top: 4rem;
			}
			@include mobile {
				width: 100%;
			}
		}
		.title-extract {
			text-align: center;
			font-family: 'IM Fell English SC', serif;
			font-size: 1.7rem;
		}
		.text {
			margin: auto;
			text-align: left;
			width: 100%;
			margin-top: 1.5rem;
			padding-left: 38%;
			@include minidesktop {
				padding-left: 28%;
			}
			@include mobile {
				padding-left: 3rem;
			}
		}
		.author {
			text-align: right;
			font-family: 'handwriter';
			font-weight: 900;
			font-size: 1.2rem;
			width: 70%;
			margin-top: 0.5rem;
			@include minidesktop {
				width: 80%;
			}
			@include mobile {
				width: 100%;
				padding-bottom: 2rem;
				padding-right: 1rem;
			}
		}
	}
}

#part-2 {
	position: relative;
	background: #f3dfc1;
}

#header {
	display: flex;
	width: 85%;
	margin: auto;
	@include mobile {
		display: block;
		width: 100%;
	}
	#hand-left, #hand-right {
		width: 16%;
		margin: auto;
		display: block;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		@include mobile {
			display: none;
		}
	}
	#hand-left {
		margin-left: 0;
	}
	#hand-right {
		margin-right: 0;
	}
	.hand {
		width: 7rem;
		margin: auto;
		display: block;
		margin-top: 1rem;
	}
	#big-title {
		background: #f3dfc1;
		width: 100%;
		position: relative;
		font-size: 3.5rem;
		font-family: "old";
		padding: 1rem;
		padding-left: 0;
		padding-right: 0;
		text-align: center;
		margin: 0;
		font-variant: small-caps;
		@include mobile {
			width: 100%;
			font-size: 2.1rem;
			padding: 0;
			margin: auto;
			line-height: 100%;
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
		br {
			display: none;
			@include mobile {
				display: block;
			}
		}
	}
}

#next-publications {
	background: #f3dfc1;
	position: relative;
	width: 100%;
	margin: auto;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	padding-bottom: 2rem;
}

#top-part-2 {
	display: block;
	 #band-part-2 {
		padding-top: 1rem;
		margin-bottom: 2rem;
		@include mobile {
			margin-bottom: 1rem;
		}
		.band {
			width: 100%;
			height: 3rem;
			margin: auto;
			font-size: 2.1rem;
			text-align: center;
			font-family: "newpaper-italic";
			@include minidesktop {
				line-height: 130%;
				font-size: 1.6rem;
				height: 2rem;
			}
			@include mobile {
				height: auto;
				line-height: 110%;
				font-size: 1.2rem;
			}
		}
		.author-quote {
			font-family:"handwriter";
			font-size: 1.1rem;
			text-align: right;
			margin-right: 0;
			margin-left: auto;
			display: block;
			margin-top: -1rem;
			@include mobile {
				margin-top: 0;
			}
		}
		.mobile {
			display: none;
			@include mobile {
				display: block;
			}
		}
	}
}

#publications-part-2 {
	display: flex;
	@include mobile {
		display: block;
	}
}

#publications2 {
	width: 22%;
	padding-top: 0;
	margin: auto;
	margin-top: 0;
	margin-left: 0;
	@include mobile {
		width: 100%;
	}
	#mounic {
		.title {
			border-bottom: 2px solid black;
			margin: auto;
			display: flex;
		}
		.title-mounic {
			display: block;
			font-variant: small-caps;
			font-family: "old";
			font-size: 2rem;
			font-weight: 900;
			border-top: 2px solid black;
			@include minidesktop {
				font-size: 1.6rem;
			}
			@include mobile {
				font-size: 2rem;
			}
		}
		.inbook {
			text-align: center;
			font-family: "newpaper-italic";
			font-variant: normal;
			font-size: 1rem;
			padding-bottom: 0.2rem;
			padding-right: 0.2rem;
			display: flex;
			@include mobile {
				padding-top: 0.2rem;
				padding-bottom: 0;
			}
		}
		.recueil {
			font-size: 1.5rem;
			line-height: 120%;
			font-family: "newpaper-bold";
			display: flex;
			@include minidesktop {
				font-size: 1.1rem;
			}
			@include mobile {
				font-size: 1.3rem;
				padding-left: 0.5rem;
			}
		}
		.text {
			padding-top: 1rem;
			font-size: 1rem;
		}
		.author {
			text-align: right;
			font-size: 2rem;
			font-family: "handwriter2";
			margin-top: 2rem;
		}
		.street {
			width: 100%;
			margin: auto;
			display: block;
			margin-bottom: 2rem;
			margin-top: 2rem;
			@include minidesktop {
				width: 65%;
				margin-top: 0;
				margin-bottom: 1rem;
			}
			@include mobile {
				margin-top: 0;
			}
		}
	}
}


#publication3 {
	width: 33%;
	margin: auto;
	margin-top: 0;
	@include minidesktop {
		width: 31%;
	}
	@include mobile {
		width: 100%;
	}
	#spitzer {
		display: block;
		.spitzer {
			border: thick double black;
			width: 90%;
			margin: auto;
			padding-right: 0.5rem;
			display: block;
			@include minidesktop {
				width: 75%;
			}
			@include mobile {
				display: none;
			}
		}
	}
	#marcelle {
		.title {
			font-family: "newpaper-bold";
			font-variant: small-caps;
			font-size: 2.4rem;
			margin-top: -1.5rem;
			@include minidesktop {
				font-size: 1.6rem;
				margin-top: -1rem;
				width: 100%;
			}
			@include mobile {
				margin-top: 1rem;
				line-height: 80%;
				font-size: 2.4rem;
				margin-bottom: 0;
			}
		}
		.resume {
			font-family: "newpaper-italic";
			padding-bottom: 0.5rem;
			margin-top: -0.5rem;
			font-size: 2rem;
			padding-left: 0;
			@include minidesktop {
				padding-bottom: 0.5rem;
 				margin-top: 0rem;
 				font-size: 1.3rem;
			}
			@include mobile {
				padding-bottom: 0;
			}
		}
		.subtitle {
			font-family: "old";
			font-variant: small-caps;
			font-size: 1.4rem;
			border-top: 2px solid black;
			border-bottom: 2px solid black;
			margin-bottom: 1rem;
			padding-bottom: 0.4rem;
			@include minidesktop {
				font-size: 1.2rem;
				padding-bottom: 0.2rem;
			}
			@include mobile {
				font-size: 1.4rem;
				padding-bottom: 0.4rem;
			}
		}
		.question {
			font-style: italic;
			font-size: 0.95rem;
			line-height: 135%;
			@include minidesktop {
				font-size: 1rem;
			}
		}
		.col-left {
			padding-right: 0.5rem;
			width: 50%;
			@include minidesktop {
				width: 100%;
			}
			@include mobile {
				width: 100%;
			}
		}
		.col-right {
			padding-left: 0.5rem;
			width: 50%;
			@include minidesktop {
				width: 100%;
			}
			@include mobile {
				width: 100%;
				padding-top: 1rem;
				padding-left: 0;
			}
		}
		.text-block {
			display: flex;
			text-align: justify;
			@include minidesktop {
				display: block;
			}
			@include mobile {
				display: block;
			}
		}
		.text {
			line-height: 140%;
			i {
				padding-top: 1rem;
				line-height: 0.7;
				@include mobile {
					margin-top: 2rem;
				}
			}
			br {
				margin-bottom: 1.3rem;
			}
		}
	}
}

#publication4 {
	display: block;
	margin: auto;
	margin-top: 0;
	width: 22%;
	@include minidesktop {
		margin-top: 2rem;
	}
	@include mobile {
		margin-top: 3rem;
		width: 100%;
	}
	.border {
		border: 2px solid black;
		padding: 1rem;
		margin-bottom: 2rem;
	}
	#graves, #todo {
		text-align: center;
		border: thick double black;
		width: 100%;
		margin: auto;
		padding-top: 1rem;
		padding-bottom: 1rem;
		@include mobile {
			padding-bottom: 0;
		}
		.title {
			font-variant: small-caps;
			font-family: "old";
			font-size: 1.6rem;
			padding-bottom: 1rem;
		}
		.graves {
			width: 12rem;
			@include minidesktop {
				width: 10rem;
			}
		}
		.recueil {
			font-size: 1.1rem;
			font-size: 3rem;
			font-family: "handwriter2";
			padding-bottom: 1rem;
		}
		.collection {
			font-family:"newpaper";
			font-variant: small-caps;
			font-size: 1rem;
			@include mobile {
				padding-bottom: 1rem;
			}
		}
	}
	#samuel {
		.head {
			display: flex;
		}
		.title {
			font-family:"newpaper-bold";
			font-variant: small-caps;
			font-size: 4.5rem;
			line-height: 90%;
			@include minidesktop {
				font-size: 3.5rem;
			}
			@include mobile {
				font-size: 4.2rem;
			}
		}
		.img {
			width: 8rem;
			@include minidesktop {
				width: 6rem;
			}
			@include mobile {
				width: 7rem;
			}
		}
		.recueil {
			font-family:"old";
			font-variant: small-caps;
			font-size: 2.2rem;
			margin-bottom: 0.5rem;
			@include minidesktop {
				font-size: 1.6rem;
				line-height: 95%;
				margin-top: 0rem;
			}
			@include mobile {
				line-height: 80%;
				margin-top: 0;
			}
		}
		.next-recueil {
			font-size: 1rem;
			text-align: right;
			margin-top: -0.5rem;
			@include minidesktop {
				margin-top: 0;
			}
			@include mobile {
				margin-top: 0;
			}
		}
		.subtitle {
			font-family:"newpaper";
			font-variant: small-caps;
			font-size: 1.5rem;
			border-top: 2px solid black;
			border-bottom: 2px solid black;
			@include minidesktop {
				font-size: 1.3rem;
			}
			@include mobile {
				font-size: 1.5rem;
			}
		}
		.resume {
			text-align: justify;
			font-style: italic;
			font-size: 0.95rem;
		}
		.big-resume {
			font-family:"newpaper-bold";
			font-variant: small-caps;
			font-style: normal;
			font-size: 1.3rem;
		}
		.info {
			display: flex;
			margin-top: 0.5rem;
			border-bottom: 2px solid black;
			border-top: 2px solid black;
		}
		.numero {
			font-family:"newpaper-italic";
			font-weight: 900;
			font-size: 1rem;
			width: 10%;
		}
		.separator-points {
			border-bottom: 2px dotted black;
			width: 80%;
			margin-bottom: 0.3rem;
		}
		.price {
			font-family:"newpaper";
			width: 10%;
		}
	}
	#todo {
		border: none;
		.title {
			font-variant: small-caps;
			font-family: "old";
		}
		.recueil {
			font-family: "handwriter2";
		}
		.resume {
			text-align: justify;
			font-size: 1rem;
			font-family:"newpaper-italic";
			padding-bottom: 1rem;
			@include minidesktop {
				font-size: 1.1rem;
			}
			@include mobile {
				padding-left: 10%;
				padding-bottom: 0;
			}
		}
		.collection {
			font-family:"newpaper";
			font-variant: small-caps;
		}
		.book {
			width: 15rem;
		}
	}
}

#publications5 {
	width: 18%;
	margin: auto;
	margin-right: 0;
	margin-top: 0;
	padding-left: 2rem;
	padding-right: 1rem;
	border-left: 1px solid black;
	display: inline;
	@include minidesktop {
		width: 20%;
		padding-left: 1rem;
		padding-right: 0;
	}
	@include mobile {
		width: 100%;
		border: none;
		padding-left: 2rem;
		padding-right: 1rem;
	}
	i {
		font-family: "handwriter2";
		font-style: normal;
		font-size: 2rem;
	}
	.title {
		border: 1px solid black;
		margin: auto;
		font-size: 2.5rem;
		text-align: center;
		margin-bottom:1rem;
		padding-bottom: 0.5rem;
		font-variant: small-caps;
		font-family: "newpaper";
		@include minidesktop {
			font-size: 1.8rem;
		}
		@include mobile {
			font-size: 2.2rem;
			margin-top: 3rem !important;
		}
	}
	.author-quote {
		font-family:"handwriter";
		font-size: 1.5rem;
		text-align: right;
		margin-right: 0;
		margin-left: auto;
		display: block;
		margin-top: -3rem;
		margin-bottom: 1.5rem;
		@include minidesktop {
			font-size: 1.3rem;
		}
		@include mobile {
			margin-top: -1rem;
			font-size: 1.5rem;
		}

	}
	.text {
		font-size: 1.1rem;
		@include minidesktop {
			font-size: 1rem;
		}
		@include mobile {
			font-size: 1rem;
		}
	}
	.text-next {
		font-size: 1rem !important;
		.title {
			@include mobile {
				margin-top: 2rem !important;
			}
		}
	}
	.inbook {
		font-family: "newpaper-italic";
		margin-top: 1rem;
		text-align: right;
		margin-top: 1.5rem;
		font-size: 1.1rem;
	}
	.author {
		font-family: "old";
		text-align:right;
		display: block;
		margin-top: 0.5rem;
		font-size: 0.9rem;
		@include minidesktop {
			margin-top: 0;
		}
	}
	.desktop {
		@include mobile {
			display: none !important;
		}
	}
}

.separator-text {
	width: 40%;
	border-bottom: 2px solid black;
	margin: auto;
	display: block;
	padding: 1rem;
	margin-bottom: 2rem;
}
