html, body{
	padding: 0;
	margin: 0;
	font-family: "newpaper";
	scroll-behavior: smooth;
	background: #f3dfc1;
}

$screen-desktop: 768px;

@mixin mobile {
	@media screen and ( max-width: #{$screen-desktop - 0.02px}) {
		@content
	}
}

@mixin desktop {
	@media screen and (max-height: 900px) {
		@content;
	}
}


body[data-page="club"]{
    .img-sep {
		width: 100% !important;
		.img {
			width: 70% !important;
			margin: 2rem auto;
			margin-top: 0;
			display: block;
			transform: rotate(360deg);
			margin-bottom: 3rem;
		}
	}
	.background-wrapper {
		&:after {
			background-color: rgba(0,0,0,0.75);
			@include mobile {
				background-color: rgba(0,0,0,0.7);
			}
		}
		&.open::after {
			background: rgba(0, 0, 0, 0.8) !important;
		}
	}
}


.mobile {
	display: none !important;
	@include mobile {
		display: block !important;
	}
}


.icon-hand {
	width: 4rem;
	margin-top: 0.7rem;
}

.d-flex {
	display: flex;
	padding-left: 2rem;
	padding-right: 2rem;
	@include minidesktop {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
	@include mobile {
		display: block;
		padding: 1rem;
	}
}

.img-sep {
	.img {
		width: 80%;
		margin: 3rem auto;
		margin-top: 0;
		display: block;
		transform: rotate(360deg);
	}
}

.text-top-page {
	position: absolute;
	width: 100%;
	color: #f3dfc1;
	z-index: 1;
	bottom: 10%;
	left: 50%;
	transform: translate(-50%,-50%);
	width: 70%;
	font-size: 1.2rem;
	font-family: "old";
	text-align: center;
	a {
		text-decoration: underline;
	}
	@include minidesktop {
		display: block;
		width: 80%;
		left: 50%;
		bottom: 10%;
		transform: translate(-50%,-50%);
		font-size: 1rem;

	}
	@include mobile {
		display: none;
	}
}

#program {
	background: #f3dfc1;
	display: flex;
	line-height: normal;
	padding-bottom: 2rem;
	position: relative;
	z-index: 1;
	@include minidesktop {
		padding-top: 1rem;
	}
	@include mobile {
		display: block;
		width: 100%;
		padding-top: 0;
	}
	#event-1, #event-2, #event-3, #event-4, #event-5 {
		width: 20%;
		margin-top: 1rem;
		text-align: center;
		border-right: 1px solid black;
		padding: 1rem;
		@include minidesktop {
			padding-top: 0;

		}
		@include mobile {
			display: block;
			width: 100%;
			margin-top: 0;
			border-right: none;
			padding: 0;
		}
	}
	.read-more {
		width: auto;
		font-size: 1rem;
		@include mobile {
			width: 75%;
			padding-top: 1rem;
			font-size: 1.2rem;
		}
	}
	.separator-event {
		display: none;
		@include mobile {
			display: block;
			padding-top: 2.5rem;
			border-bottom: 2px solid black;
			width: 95%;
			margin: auto;
		}
	}
	#event-1 {
		.title {
			font-style: italic;
		}
	}
	#event-2 {
		.title {
			font-family: 'newpaper-bold';
		}
	}
	#event-4 {
		.title {
			font-family: 'newpaper-bold';
		}
	}
	#event-5 {
		border-right: none;
		.title {
			font-style: italic;
		}
		@include mobile {
			.separator-event {
				display: none;
			}
		}
	}
	.title {
		font-variant: small-caps;
		font-size: 2rem;
		padding: 0.5rem;
		line-height: 25px;
		@include minidesktop {
			padding-top: 0;
			padding-left: 0;
			padding-right: 0;
		}
		@include mobile {
			padding-left: 1rem;
			padding-right: 1rem;
			line-height: 80%;
			padding-top: 1rem;
			padding-bottom: 0;
		}
	}
	.date {
		border-top: 1px solid black;
		border-bottom: 1px solid black;
		width: 70%;
		padding: 0.5rem;
		margin: auto;
		margin-top: 1rem;
		margin-bottom: 1rem;
		font-weight: 900;
		width: 95%;
		@include minidesktop {
			padding-left: 0;
			padding-right: 0;
			width: 100%;
		}
		@include mobile {
			font-variant: small-caps;
			font-size: 1rem;
			font-weight: normal;
			width: 90%;
			margin-bottom: 0;
		}
	}
	.resume {
		padding: 0.5rem;
		text-align: justify;
		text-overflow: ellipsis;
		@include minidesktop {
			padding: 0.2rem;
		}
		@include mobile {
			padding: 1rem;
		}
	}
	img {
		width: 100%;
		padding: 0.5rem;
		position: relative;
		@include minidesktop {
			width: 100%;
			padding-left: 0;
			padding-right: 0;
		}
		@include mobile {
			padding-left: 1rem;
			padding-right: 1rem;
			padding-top:  1rem;
			padding-bottom: 0;
		}
	}
}

.next-points {
	padding-bottom: 0.5rem;
	@include minidesktop {
		padding-top: 0.5rem;
	}
	@include mobile {
		padding-top: 1rem;
	}
}

#see-events, .quote-founder, #end {
	position: relative;
	height: 100vh;
	&:before {
		background: rgba(0, 0, 0, 0.7);
		position: absolute;
		height: 100%;
		width: 100%;
		content: "";
		top: 0;
		left: 0;
		z-index: 1;
	}
	&:after {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
	.fond {
		background-position: 100% 0%;
		background-size: cover;
		position: relative;
		height: 100vh;
		position: relative;
		display: flex;
		@media screen and (max-width: 768px) {
			background-position: calc(100vw - 80%);
			align-items: center;
			justify-content: center;
			display: flex;
		}
	}
	.title {
		position: absolute;
		transform: translate(-50%,-50%);
		top: 35%;
		left: 50%;
		color:  #f8ead5;;
		font-size: 5rem;
		z-index: 1000;
		font-family: "handwriter";
		font-weight: 700;
		@include minidesktop {
			font-size: 4rem;
		}
		@media screen and (max-width: 768px) {
			font-size: 1.8rem;
			text-align: center;
			width: 90%;
			line-height: 105%;
		}
	}
}

.around {
	border: 1px solid #f3dfc1;
	padding: 0.7rem 1rem;
	z-index: 3;
	margin: auto;
}

.typewriter a {
	@include mobile {
		position: relative;
		top: 0;
		left: 0;
		margin: auto;
		display: block;
		text-align: center;
		font-size: 1.3rem;
	}
	position: relative;
	top: 0;
	left: 0;
	display: block;
	text-align: center;
	display: block;
	z-index: 3;
	font-size: 1.5rem;
	font-family: "old";
	overflow: hidden; /* Ensures the content is not revealed until the animation */
	border-right: .15em solid orange; /* The typwriter cursor */
	white-space: nowrap; /* Keeps the content on a single line */
	margin: 0 auto; /* Gives that scrolling effect as the typing happens */
	letter-spacing: .15em; /* Adjust as needed */
	color:  #f8ead5;
	animation:
		typing 10.5s steps(30, end),
		blink-caret .2s step-end infinite
}


/* The typing effect */
@keyframes typing {
	from { width: 0 }
	to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
	from, to { border-color: transparent }
	50% { border-color:  #f8ead5; }
}


#club {
	background: #f3dfc1;
	z-index: 1;
	position: relative;
	padding-bottom: 2rem;
	.club-sep {
		width: 80%;
		margin-top: 3rem;
		margin-bottom: 2rem;
		margin-left: auto;
		margin-right: auto;
		transform: rotate(0deg);
	}
}

#club-presentation {
	display: flex;
	line-height: normal;
	position: relative;
	margin: auto;
	top: 2rem;
	padding: 2rem;
	padding-top: 0;
	width: 100%;
	@include minidesktop {
		top: 0;
		padding: 2rem;
	}
	@include mobile {
		display: block;
		width: 100%;
		top: 0;
		padding: 1rem;
	}

	.text-part {
		display: block;
		margin-right: auto;
		width: 50%;
		@include mobile {
			width: 100%;
		}
	}
	.title {
		font-family: "newpaper-italic";
		font-size: 1.7rem;
		margin-bottom: 1rem;
		@include mobile {
			margin-bottom: 0;
		}
	}
	.img-mobile {
		@include mobile {
			width: 100%;
			display: block;
			margin: auto;
			margin-top: 1rem;
		}
		display: none;
	}
	.citation-block {
		.citation {
			font-family: 'IM Fell English SC', serif;
			font-size: 4rem;
			line-height: 50px;
			width: 90%;
			margin: auto;
			z-index: 2;
			@include minidesktop {
				font-size: 3.7rem;
				line-height: 80%;
				width: 100%;
				padding-right: 1rem;
			}
			@include mobile {
				font-size: 3rem;
				width: 100%;
				position: relative;
				top: 0;
				margin: auto;
				line-height: 75%;
				margin-top: 1rem;
				margin-bottom: 1.5rem;
			}
		}
	}
	.text {
		text-align: left;
		color: #584746;
		z-index: 1;
		width: 90%;
		margin: auto;
		position: relative;
		p:first-letter {
			font-size: 1.5rem;
			font-family: "handwriter";
			font-weight: 900;
		}
		@include minidesktop {
			width: 100%;
			padding-right: 1rem;;
		}
		@include mobile {
			margin: auto;
			text-align: justify;
			width: 100%;
			padding-right: 0;
		}
		.text-cut {
			width: 80%;
			text-align: left;
			padding-right: 3rem;
			@include mobile {
				width: 100%;
				text-align: justify;
				padding-right: 0;
			}
		}
	}
	.img-verlaine {
		width: 100%;
		margin: auto;
		display: block;
	}
	.verlaine-desktop {
		position: absolute;
		width: 9rem;
		bottom: -3rem;
		right: 0;
		@include minidesktop {
			width: 8rem;
			right: 2rem;
		}
		@include mobile {
			position: relative;
			width: 10rem;
			margin: auto;
			display: block;
			margin-top: 0;
			padding-bottom: 3rem;
			right: unset;
		}
	}
	.read-more {
		justify-content: center;
		text-decoration: none;
		text-decoration: underline;
	}
	.img-jprosnay {
		width: 90%;
		margin: auto;
		position: relative;
		@include mobile {
			width: 100%;
		}
		.img {
			margin-top: 0;
		}
		.aragon {
			position: absolute;
			width: 10rem;
			bottom: 5rem;
			left: 2rem;
			@include mobile {
				display: none;
			}
		}
	}
	.title-founder {
		margin-top: 2rem;
		margin-bottom: 1rem;
		@include mobile {
			margin-top: 0;
			margin-bottom: 2rem;
		}
	}
	.img-part {
		display: block;
		margin-left: auto;
		margin-right: 0;
		width: 50%;
		position: relative;
		@include mobile {
			width: 100%;
		}
		.img {
			@include mobile {
				display: none;
			}
		}
		.micro {
			position: absolute;
			width: 5rem;
			top: 3rem;
			right: 0;
			@include mobile {
				display: none;
			}
		}
	}
	b {
		font-style: normal !important;
		font-size: 4rem;
		color: #f3dfc1;
		font-weight: normal;
	}
	.img {
		margin-top: 0;
		width: 100%;
		z-index: 1;
		position: relative;
		filter: grayscale(50%);
		margin-top: 1rem;
		&:before {
			background: rgba(0, 0, 0, 0.4);
			position: absolute;
			height: 100%;
			width: 100%;
			content: "";
			top: 0;
			left: 0;
			z-index: 1000;
		}
	}
	.text-founder {
		text-align: justify;
		color: #584746;
		z-index: 1;
		width: 75%;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 4rem;
		@include minidesktop {
			width: 100%;
			margin-right: auto;
			margin-left: 0;
			margin-bottom: 2rem;
		}
		@include mobile {
			padding-top: 0;
			width: 100%;
			margin-top: -1rem;
		}
		i {
			font-size: 1.5rem;
			font-family: "handwriter";
			font-weight: 900;
		}
	}
	.text-part-founder {
		width: 100%;
		margin: auto;
		position: relative;
		margin-top: 0;
		@include mobile {
			width: 100%;
		}
		.read-more {
			@include mobile {
				width: auto;
			}.img-sep {
				.img {
					width: 80%;
					margin: 2rem auto;
					margin-top: 0;
					display: block;
					transform: rotate(360deg);
					margin-bottom: 3rem;
				}
			}
		}
	}
	.img-sep {
		.img {
			width: 80%;
			margin: 2rem auto;
			margin-top: 0;
			display: block;
			transform: rotate(360deg);
			margin-bottom: 3rem;
		}
	}
	.citation-founder {
		font-family: 'IM Fell English SC', serif;
		margin: auto;
		text-align: left;
		line-height: 50px;
		font-size: 4rem;
		z-index: 2;
		margin-top: 1rem;
		@include minidesktop {
			margin-top: 2rem;
			font-size: 3.7rem;
  			line-height: 80%;
		}
		@include mobile {
			position: relative;
			margin: auto;
			font-size: 3rem;
			line-height: 75%;
			top: 0;
			margin-top: 1rem;
			br {
				display: block;
			}
		}
	}
}


.quote-founder {
	position: relative;
	&:before {
		background: rgba(0, 0, 0, 0.65) !important;
		position: absolute;
		height: 100%;
		width: 100%;
		content: "";
		top: 0;
		left: 0;
		z-index: 1;
	}
	.quote {
		color:  #f8ead5;
		font-size: 6rem;
		font-family: "handwriter2";
		position: absolute;
		transform: translate(-50%, -50%);
		top: 45%;
		left: 50%;
		z-index: 1;
		width: 80%;
		text-align: center;
		line-height: 75%;
		@include mobile {
			font-size: 3.5rem !important;
			line-height: 80%;
		}
		.title-quote {
			line-height: 130%;
			font-size: 4.5rem;
			@include minidesktop {
				font-size: 3.5rem;
			}
			@include mobile {
				font-size: 3rem;
			}
		}
	}
}

#poetry-section {
	position: relative;
	z-index: 2;
	background: #f3dfc1;
	display: flex;
	@include mobile {
		display: block;
	}
	#content-poetry {
		width: 83%;
		background: #f3dfc1;
		position: relative;
		z-index: 1;
		margin: 0;
		padding: 1rem;
		@include minidesktop {
			width: 80%;
		}
		@include mobile {
			width: 100%;
			padding-top: 0;
		}
		.title-poets {
			display: block;
			width: 50%;
			position: relative;
			font-family: "newpaper-italic";
			font-size: 1.7rem;
			@include mobile {
				width: 100%;
				padding-top: 1rem;
			}
		}
		#poetry {
			position: relative;
			width: 100%;
			margin: auto;
			background: #f3dfc1;
			padding-left: 1rem;
			padding-right: 1rem;
			display: flex;
			@include minidesktop {
				padding-left: 1rem;
				padding-right: 1rem;
			}
			@include mobile {
				padding-left: 0;
				padding-right: 0;
			}
			.content {
				width: 100%;
				display: flex;
				margin: auto;
				margin-top: 0;
				@include mobile {
					display: block;
				}
			}
			.text-part {
				display: block;
				margin-right: auto;
				width: 100%;
				@include mobile {
					width: 100%;
				}
			}
			.citation {
				position: relative;
				font-family: 'IM Fell English SC', serif;
				width: 73%;
				line-height: 60px;
				margin-top: 0.5rem;
				font-size: 4rem;
				z-index: 2;
				line-height: 80%;
				@include minidesktop {
					font-size: 3.7rem;
					width: 90%;
					margin-top: 1rem;
				}
				@include mobile {
					font-size: 3rem;
					position: relative;
					line-height: 80%;
					width: 100%;
					margin-top: 1rem;
					br {
						display: none;
					}
				}
			}
			.text {
				text-align: justify;
				color: #584746; //tons marrons clairs
				z-index: 1;
				width: 100%;
				display: flex;
				gap: 0.5rem;
				@include mobile {
					display: block;
					margin-top: 0;
					gap: unset;
					margin-top: 1rem;
				}
				.text-section {
					display: flex;
					width: 100%;
					margin: auto;
					@include minidesktop {
						width: 100%;
						display: block;
						margin-bottom: 2rem;
					}
					@include mobile {
						display: block;
						margin-bottom: 1rem;
					}
				}
				.size-text {
					width: 50%;
					padding-right: 1rem;
					@include minidesktop {
						width: 100%;
						padding-right: 0;
					}
					@include mobile {
						width: 100% !important;
						margin-top: -0.5rem;
						padding-right: 0;
					}
				}
				.short {
					@include minidesktop {
						padding-right: 1rem;
					}
				}
				i {
					font-size: 1.5rem;
					font-family: "handwriter";
					font-weight: 900;
					margin-top: -2rem;
					margin-left: 1rem;
					@include mobile {
						margin-left: 0;
						line-height: 100%;
					}
				}
			}
			.img-section {
				width: 50%;
				@include minidesktop {
					width: 50%;
				}
				@include mobile {
					width: 100%;
				}
				img {
					width: 70%;
					margin: auto;
					display: block;
					margin-top: 0;
					@include minidesktop {
						width: 90%;
						margin-top: 0rem;
					}
					@include mobile {
						width: 100%;
					}
				}
			}
			.block-text {
				width: 50%;
				@include minidesktop {
					width: 50%;
				}
				@include mobile {
					width: 100%;
				}
			}
			.img-part {
				display: flex;
				margin: auto;
				width: 100%;
				position: relative;
				margin-top: 0;
				@include mobile {
					width: 100%;
					margin-top: 1rem;
					display: block;
				}
				.img {
					width: 25rem;
					margin-left: auto;
					margin-right: auto;
					display: block;
					margin-top: 4rem;
					padding-left: 3rem;
					margin-left: 0;
					@include minidesktop {
						display: none;
					}
					@include mobile {
						width: 100%;
						margin-top: 2rem;
						padding-left: 0;
						margin-bottom: 2rem;
					}
				}
				.read-more {
					text-decoration: underline;
					font-size: 1.3rem;
					margin-top: auto;
					margin-bottom: auto;
					width: auto;
					align-items: initial;
					margin-left: 0;
					@include minidesktop {
						margin: auto;
						font-size: 1.1rem;
						text-align: center;
						margin-top: 3rem;
					}
					@include mobile {
						font-size: 1.3rem;
						margin-bottom: 2rem;
						margin-top: 3rem;
					}
					&::before {
						background: url("../images/hand_icon-right.png") no-repeat bottom;
						background-size: contain;
						content: "";
						display: block;
						height: 1.75rem;
						margin-right: 0.7rem;
						width: 3rem;
					}
					&::after {
						background: url("../images/hand_icon-right.png") no-repeat bottom;
						transform: scaleX(-1);
						background-size: contain;
						content: "";
						display: block;
						height: 1.75rem;
						margin-left: 0.7rem;
						width: 3rem;
					}
				}
			}
			b {
				font-style: normal !important;
				font-size: 3.7rem;
				color: #f3dfc1;
			}
		}
	}
	.img-sep {
		.img {
			width: 60%;
			margin: 0 auto;
			margin-top: 0;
			display: block;
			transform: rotate(360deg);
		}
	}
	#col-right-poets {
		width: 17%;
		display: block;
		padding-left: 1rem;
		border-left: 1px solid;
		margin-top: 2rem;
		margin-bottom: 2rem;
		@include minidesktop {
			width: 20%;
		}
		@include mobile {
			width: 100%;
			padding-top: 0;
			margin-bottom: 0;
			margin-top: 1rem;
			padding-left: 0;
			border-left: none;
		}
		.title-poem {
			text-align: center;
			font-size: 1.2rem;
			padding: 1rem;
			padding-top: 0;
			@include minidesktop {
				padding-left: 0;
				padding-right: 0;
				font-size: 1rem;
			}
			@include mobile {
				width: 90%;
  				margin: auto;
				font-size: 1.5rem;
			}
		}
		.text-poem {
			font-size: 1rem;
			color: #584746;
			width: 90%;
			margin: auto;
			@include minidesktop {
				font-size: 0.95rem;
				width: 100%;
				margin: auto;
			}
			@include mobile {
				font-size: 1rem;
				width: 70%;
				margin: auto;
			}
		}
		.author {
			font-family: "handwriter";
			text-align: right;
			margin-right: 0;
			margin-left: auto;
			display: block;
			padding-right: 1rem;
			font-size: 1.2rem;
			width: 100%;
			padding-bottom: 1rem;
			@include mobile {
				padding-top: 0.5rem;
				padding-bottom: 2rem;
				margin-left: auto;
				margin-right: 0;
			}
		}
	}
	.img-sep {
		width: 100% !important;
		margin: 3rem auto;
		margin-top: 0;
		display: block;
		transform: rotate(360deg);
	}
}

.quote-poetry {
	.quote {
		top: 50% !important;
		.title-quote {
			@include minidesktop {
				line-height: 90% ;
				font-size: 4rem ;
				width: 80%;
				display: inline;
				margin: auto;
			}
			@include mobile {
				font-size: 2.5rem;
				text-align: center;
				width: 95%;
				line-height: 95%;
			}
		}
		i {
			font-size: 5rem;
			@include minidesktop {
				font-size: 5rem ;
			}
			@include mobile {
				font-size: 3.5rem;
				text-align: center;
				width: 95%;
			}
		}
	}
}

#quote-end {
	position: relative;
	height: 100vh;
	&:before {
		background: rgba(0, 0, 0, 0.5) !important;
		position: absolute;
		height: 100%;
		width: 100%;
		content: "";
		top: 0;
		left: 0;
		z-index: 1;
	}
	.quote {
		color:  #f8ead5;
		font-size: 6rem;
		font-family: "handwriter2";
		position: absolute;
		transform: translate(-50%, -50%);
		top: 50%;
		left: 50%;
		z-index: 1;
		width: 100%;
		text-align: center;
	}
}

.podcast {
	background: #f3dfc1;
	z-index: 1;
	position: relative;
	padding-bottom: 1rem;
	display: block;
	.desktop {
		width: 13%;
		@include minidesktop {
			width: 17%;
		}
	}
	.col-left, .col-right {
		line-height: normal;
		width:13%;
		text-align: justify;
		@include minidesktop {
			font-size: 0.95rem;
			width: 17%;
		}
		@include mobile {
			width: 90%;
			text-align: justify;
			margin: auto;
		}
		i {
			font-size: 2.5rem;
			line-height: 80%;
		}
	}
	.col-left {
		color: #584746;
		width: 100%;
		@include mobile {
			width: 100%;
			font-size: 1rem;
		}
		.title-poem {
			padding: 0.5rem;
			padding-bottom: 1rem;
			font-family: "newpaper";
			font-size: 2rem;
			border: 2px solid black;
			font-variant: small-caps;
			text-align: center;
			margin-bottom: 2rem;
			color: black;
			line-height: 90%;
			@include minidesktop {
				font-size: 1.8rem;
			}
			@include mobile {
				font-size: 1.6rem;
				padding-top: 0.6rem;
			}
		}
		i {
			font-size: 2.5rem;
			line-height: 50%;
		}
	}
	.author-podcast {
		display: flex;
		margin-top: 1.5rem;
		align-items: center;
	}
	.micro {
		width: 3rem;
		display: flex;
		align-items: end;
		@include mobile {
			margin-top: 1.8rem;
		}
		img {
			width: 2.2rem;
			margin-left: auto;
			margin-right: 1rem;
			border: 1px solid black;
			border-radius: 100%;
			padding: 0.5rem;
		}
	}
	.author {
		font-family: "handwriter";
		text-align: right;
		padding-right: 1rem;
		font-size: 1.2rem;
		width: 100%;
		padding-bottom: 1rem;
		@include mobile {
			padding-top: 0.5rem;
			padding-bottom: 0;
			margin-left: auto;
			margin-right: 0;
		}
		span {
			text-align: right;
			margin-left: auto;
			margin-right: 0;
			color: black;
		}
	}
	.col-center {
		width: 74%;
		padding-left: 2rem;
		padding-right: 2rem;
		padding-top: 4rem;
		@include minidesktop {
			width: 66%;
			padding-left: 1.5rem;
			padding-right: 1.5rem;
		}
		@include mobile {
			width: 100%;
			padding: 0;
		}
	}
	.col-right {
		margin-top: -4rem;
		color: #584746;
		@include minidesktop {
			margin-top: -3rem;
		}
		@include mobile {
			display: none;
		}
	}
	.text-mobile {
		display: none;
		@include mobile {
			display: block;
			margin-top: 1rem;
		}
	}
	.title-podcasts {
		font-family: "newpaper-italic";
		font-size: 1.7rem;
		padding: 2rem;
		@include minidesktop {
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
		@include mobile {
			padding-bottom: 0;
		}
	}
	.podcast-content {
		background: #f3dfc1;
		position: relative;
		z-index: 2;
		b {
			font-style: normal !important;
			font-size: 3.7rem;
			color: #f3dfc1;
		}
		.line {
			margin: auto;
			border-top: 2px solid black;
			margin-top:1.3rem;
			width: 85%;
			display: block;
		}
		.citation-block {
			position: relative;
			width: 100%;
  			margin: auto;
			margin-top: -9rem;
			@include minidesktop {
				margin-top: -8rem;
			}
			@include mobile {
				margin-top: 0;
			}
			.citation {
				font-family: 'IM Fell English SC', serif;
				width: 75%;
				line-height: 60px;
				font-size: 4rem;
				z-index: 2;
				@include minidesktop {
					padding-top: 1rem;
					width: 95%;
					font-size: 3.7rem;
				}
				@include mobile {
					padding-top: 0;
					font-size: 3rem;
					line-height: 75%;
					margin-bottom: 1rem;
					width: 100%;
				}
			}
		}
		.subtitle {
			margin-left: 0;
			margin-bottom: 1rem;
			width: 80%;
			color: #584746;
			line-height: 25px;
			margin-top: 1rem;
			@include minidesktop {
				margin:auto;
				margin-bottom: .5rem;
				width: 95%;
				margin-top: 1rem;
			}
			@include mobile {
				width: 100%;
				margin-top: 0;
				margin-bottom: 0;
			}
			i {
				font-size: 2.4rem;
				font-family: "handwriter2";
				font-weight: 500;
				line-height: 200%;
				padding-right: 0.2rem;
				line-height: 0;
				@include mobile {
					font-size: 2rem;
				}
			}
		}
		.podcast-list {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			@include minidesktop {
				width: 100%;
				margin: auto;
				margin-left: 1rem;
				display: flex;
			}
			@include mobile {
				width: 100%;
				margin-left: 0;
			}
			.podcast-episode {
				padding: 2rem;
				width: 25%;
				padding-top: 0;
				//&:nth-last-of-type(-n+4) {
				//	display: none;
				//}
				@include desktop {
					&:nth-last-of-type(-n+4) {
						display: block !important;
					}
				}
				@include minidesktop {
					padding: 1rem;
				}
				@include mobile {
					box-sizing: border-box;
					width: 50%;
					padding: 1rem;
				}
				&:nth-last-of-type(-n+4) {
					@include mobile {
						display: none !important;
					}
				}
			}
			.podcast-episode:first-child, 	.podcast-episode:nth-child(5) {
				margin-left: -2rem;
				@include minidesktop {
					margin-left:-1rem;
				}
				@include mobile {
					margin-left: 0;

				}
			}
			.podcast-episode:first-child, .podcast-episode:nth-child(2) .podcast-episode:nth-child(3), .podcast-episode:nth-child(5), .podcast-episode:nth-child(6), .podcast-episode:nth-child(7) {
				@include mobile {
					padding-left: 0;
					margin-left: 0;
				}
			}
			.podcast-episode:last-child, .podcast-episode:nth-child(4) {
				margin-right: -2rem;
				@include minidesktop {
					margin-right:0;
				}
				@include mobile {
					margin-right: 0;
					padding-right: 0;
				}
			}
			.podcast-episode:last-child, .podcast-episode:nth-child(4), .podcast-episode:nth-child(6) {
				@include mobile {
					margin-right: 0;
					padding-right: 0;
				}
			}
			.podcast-episode:last-child, .podcast-episode:nth-child(2) {
				@include mobile {
					padding-right: 0;
				}
			}
			.podcast-episode:nth-child(3) {
				@include mobile {
					padding-left: 0;
				}
			}
			.title-pod {
				padding-top: 0.5rem;
				padding-bottom: 0.3rem;
				font-size: 1rem;
				font-family: "old";
 				margin: auto;
				@include mobile {
					padding-bottom: 0;
					width: 100%;
				}
			}
			.title-pod, .resume, .date {
				padding-left: 0.5rem;
 				margin: auto;
				 @include minidesktop {
					width: 100%;
					padding-left: 0;
				}
			}
			.resume, .date {
				color: #584746;
			}
			.img {
				width: 100%;
				margin: auto;
				filter: grayscale(50%);
				display: block;
				border: 2px solid #d4c3a9;
				@include minidesktop {

				}
				@include mobile {
					width: 100%;
				}
				&:hover {
					transform: scale(1.01);
					position: relative;
				}
			}

		}
		#videos {
			margin: auto;
			width: 100%;
			padding: 2rem;
			position: relative;
			#title-youtube {
				display: flex;
			}
			.title-youtube {
				font-family: "newpaper-italic";
				font-size: 1.7rem;
				padding-bottom: 2rem;
				margin-left: 0rem;
			}
			.citation {
				font-family: 'IM Fell English SC', serif;
				width: 100%;
				line-height: 60px;
				font-size: 4.5rem;
				z-index: 2;
				font-variant: small-caps;
				text-align: center;
			}
			.separator {
				border-bottom: 2px solid black;
				width: 90%;
				margin: auto;
				margin-bottom: 1rem;
				padding-top: 1rem;
			}
			.citation-next {
				font-family: "newpaper-italic";
				font-variant: small-caps;
				font-size: 1.9rem;
				line-height: 80%;
				text-align: center;
				margin-bottom: 2rem;
			}
			#cols {
				display: flex;
				margin: auto;
			}
			.col-left, .col-right {
				width: 25%;
				.separator-col {
					width: 60%;
					border-top: 2px solid black;
					margin: auto;
				}
			}
			.col-right {
				padding-left: 1rem;
			}
			.col-center {
				width: 50%;
				#all-videos {
				}
			}
			.title-poem {
				font-family: "newpaper";
				font-size: 2.5rem;
				line-height: 90%;
				padding-top: 1rem;
				padding-bottom: 1rem;
				text-align: center;
			}
			.text {
				margin-top: 1rem;
			}
			.img {
				width: 100%;
				padding-right: 2rem;
				padding-left: 2rem;
				padding-bottom: 1rem;
				margin: auto;
				display: block;
				position: relative;
			}
		}
	}
}


#youtube {
	width: 100%;
	margin-bottom: 0;
	padding-top: 1rem;
}

.typewriter-podcast, .typewriter-video {
	position: relative;
	z-index: 2;
	text-align: center;
	font-size: 1.2rem;
	font-family: "old";
	letter-spacing: .15em; /* Adjust as needed */
	color: black;
	padding-bottom: 2rem;
	width: 100%;
	height: 3rem;
	border: 1px solid black;
	padding-top: 0.6rem;
	margin: auto;
	width: 20rem;
	@include mobile {
		display: none;
	}
}

#end {
	.fond {
		background: url("../images/jprosnay.jpg");
		background-position: 100% 26%;
		background-size: cover;
		position: relative;
		height: 100vh;
	}
	.text {
		width: 100%;
		position: absolute;
		text-align: center;
		font-size: 3rem;
		font-family: "handwriter2";
		top: 20%;
		color: white;
		z-index: 1;
		color:  #f8ead5;
	}
	b {
		font-style: normal;
		font-size: 4rem;
	}
	i {
		font-family: "handwriter2";
		font-style: normal;
		font-size: 2rem;
	}
}


#quote-founder2 {
	position: relative;
	.fond {
		background: url("../images/jprosnay.jpg") !important;
		background-position: 100% 50%;
		background-size: cover;
		position: relative;
		height: 100vh;
	}
	.quote {
		color:  #f8ead5;
		font-size: 6rem;
		font-family: "handwriter2";
		position: absolute;
		transform: translate(-50%, -50%);
		top: 50%;
		left: 50%;
		z-index: 1;
		width: 100%;
		text-align: center;
	}
}

#biography {
	background: #f3dfc1;
	z-index: 1;
	position: relative;
}


#press {
	background: #f3dfc1;
	position: relative;
	z-index:2;
	width: 100%;
	.title-press {
		font-family: "newpaper-italic";
		font-size: 1.7rem;
		padding: 2rem;
		@include minidesktop {
			padding-left: 2rem;
		}
		@include mobile {
			padding-bottom: 1rem;
			padding-left: 1rem;
		}
	}
	.subtitle {
		font-family: old;
		text-align: center;
		font-size: 2rem;
		font-style: italic;
		border-top: 2px solid black;
		padding-top: 1rem;
		width: 90%;
		margin: auto;
		@include mobile {
			font-size: 1.1rem;
			width: 90%;
			border-bottom: 2px solid black;
 			padding-bottom: 1rem;
		}
	}
	#header {
		display: flex;
		width: 75%;
		margin: auto;
		margin-top: -5rem;
		margin-bottom: 1.5rem;
		@include minidesktop {
			width: 85%;
		}
		@include mobile {
			display: block;
			margin-top: 0;
			width: 100%;
		}
		#big-title {
			background: #f3dfc1;
			width: 100%;
			position: relative;
			font-size: 4rem;
			font-family: "old";
			text-align: center;
			margin-left: 0;
			margin-right: 0;
			padding: 0;
			font-variant: small-caps;
			@include desktop {
				font-size: 3rem;
				width: 90%;
			}
			@include mobile {
				width: 90%;
				padding-top: 0;
				font-size: 2.5rem;
				line-height: 100%;
				text-align: center;
				margin: auto;
			}
		}
		#hand-left, #hand-right {
			display: block;
			@include mobile {
				display: none;
			}
		}
		#hand-left {
			margin-left: 0;
			.hand {
				-webkit-transform: scaleX(-1);
 				transform: scaleX(-1);
				 margin-left: auto;
				 margin-right: 0;
				 margin-top: 0;
			}
		}
		#hand-right {
			margin-right: 0;
			margin-bottom: 0.5rem;
			.hand {
				 margin-right: auto;
				 margin-left: 0;
				 margin-top: 0;
			}
		}
		.hand {
			width: 7rem;
			display: block;
			@include desktop {
				width: 5rem;
			}
			@include minidesktop {
				display: none;
			}
		}
	}
	#articles {
		display: flex;
		width: 95%;
		margin: auto;
		padding-top: 2rem;
		@include mobile {
			display: block;
			padding-top: 1rem;
		}
		.img-sep {
			img {
				margin: auto;
				display: block;
				margin-top: 2rem;
				margin-bottom: 0;
			}
		}
		.read-more {
			text-decoration: underline;
			margin-top: 1rem;
			width: auto;
			margin-top: 2rem;
			margin-top: 2rem;
  			margin-bottom: 3rem;
			.icon-hand {
				display: flex;
				align-content: space-evenly;
			}
			img {
				width: 3rem;
				padding: 0;
			}
			a {
				display: flex;
				 align-items: center;
			}
		}
		.verlaine, .rimbaud {
			width: 70%;
			padding-left :2rem;
			margin: auto;
			margin-top: 2rem;
			display: block;
			@include mobile {
				padding: 1rem;
				width: 15rem;
			}
		}
		.verlaine {
			@include mobile {
				display: none;
			}
		}
		.rimbaud {
			display: none;
			@include mobile {
				display: block;
				margin-top: 4rem;
				width: 40%;
				padding: 0;
			}
		}
	}
	.telerama {
		width: 25%;
		@include mobile {
			width: 95%;
			margin: auto;
			border-bottom: 1px solid black;
			margin-bottom: 1rem;
			padding: 0;
			padding-top: 0;
		}
		.img {
			width: 100%;
			padding: 0;
			padding-right: 0;
			filter: grayscale(70%);
			@include mobile {
				padding-top: 0;
				padding: 0.5rem;
			}
		}
	}
	.chretiens {
		width: 50%;
		@include mobile {
			width: 100%;
			padding: 1rem;
			padding-top: 1rem;
			display: none;
		}
		.img {
			width: 80%;
			border: 2px solid black;
			padding: 1rem;
			padding-right: 1.5rem;
			margin: auto;
			display: block;filter:
			grayscale(80%);
			@include mobile {
				width: 100%;
				padding: 1rem;
				border: 1px solid black;
			}
		}
	}
	.figaro {
		width:25%;
		@include mobile {
			width: 100%;
			padding: 1rem;
			padding-top: 1rem;
		}
		.img {
			width: 100%;
			filter: grayscale(80%);
			@include mobile {
				width: 100%;
				margin: auto;
			}
		}
	}
	.link {
		color: black;
		text-align: center;
		font-style: italic;
		margin: auto;
		display: block;
		font-family: 'IM Fell English SC', serif;
		@include mobile {
			text-decoration: underline;
		}
	}
}


#find-us {
	background: #f3dfc1;
	position: relative;
	z-index:0;
	width: 100%;
	@include mobile {
		margin-top: -0.2rem;
	}
	.title {
		font-family: 'old';
		font-variant: small-caps;
		font-size: 2.6rem;
		text-align: center;
		padding-top: 2rem;
		@include mobile {
			font-size: 2.1rem;
			line-height: 100%;
			padding: 2rem;
			padding-bottom: 0;
			text-decoration: underline;
		}
	}
	#partners {
		display: flex;
		width: 90%;
		padding: 2rem;
		padding-top: 1rem;
		margin: auto;
		@include desktop {
			width: 100%;
		}
		@include mobile {
			display: block;
		}
		.partner {
			margin: auto;
			width: 15%;
			@include mobile {
				display: block;
				margin: auto;
				width: 90%;
			}
			.link {
				.img {
					width: 100%;
					padding: 2rem;
					@include mobile {
						margin: auto;
						display: block;
						margin-bottom: 1.5rem;
						margin-top: 1.5rem;
						padding: 0;
						width: 8rem;
					}
				}
			}
		}
		.verlaine-sep {
			display: block;
			width: 80%;
			margin: auto;
			margin-bottom: 1rem;
		}
	}
}

p.drop-capital:first-letter {
	font-size: 1.5rem;
	font-family: "handwriter";
	font-weight: 900;
	margin-bottom: -1rem;
}

p.drop-capital-2:first-letter {
	font-size: 2rem;
	font-family: "handwriter2";
	font-weight: 900;
	line-height: 50%;
}
