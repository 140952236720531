*,
*::before,
*::after {
	box-sizing: border-box;
	font-weight: normal;
	line-height: normal;
	margin: 0;
}


@font-face {
	font-family: 'IM Fell English SC';
	font-display: swap;
	src: url("./fonts/IMFeENsc28P.ttf") format("truetype");
}

@font-face {
	font-family: 'test';
	font-display: swap;
	src: url("./fonts/test.ttf") format("truetype");
}


@font-face {
	font-family: "newpaper";
	font-display: swap;
	src: url("./fonts/newpaper.ttf") format("truetype");
}

@font-face {
	font-family: "newpaper-bold";
	font-display: swap;
	src: url("./fonts/newpaper-bold.ttf") format("truetype");
}

@font-face {
	font-family: "newpaper-italic";
	font-display: swap;
	src: url("./fonts/newpaper-italic.ttf") format("truetype");
}

@font-face {
	font-family: "old";
	font-display: swap;
	src: url("./fonts/old.ttf") format("truetype");
}

@font-face {
	font-family: "handwriter";
	font-display: swap;
	src: url("./fonts/handwriter.ttf") format("truetype");
}

@font-face {
	font-family: "handwriter2";
	font-display: swap;
	src: url("./fonts/handwriter2.ttf") format("truetype");
}


$screen-desktop: 768px;

@mixin mobile {
	@media screen and ( max-width: #{$screen-desktop - 0.02px}) {
		@content
	}
}

@mixin desktop {
	@media screen and (max-height: 900px) {
		@content;
	}
}

@mixin minidesktop {
	@media screen and (max-height: 900px) {
		@content;
	}
}


.mobile {
	display: none;
	@include mobile {
		display: block;
	}
}

.desktop {
	display: block;
	@include mobile {
		display: none;
	}
}


.up {
	bottom: 1rem;
	display: none;
	position: fixed;
	right: 0;
	width: 10rem;
	z-index: 4;

	&.active {
		display: block;
	}

	.icon-up {
		background-color: rgba(64,52,52,0.95) !important;
		border-radius: 50px;
		bottom: 0;
		cursor: pointer;
		padding: 1rem;
		position: absolute;
		right: 1rem;
		width: 3rem;
	}
}

.menu-mobile {
	position: absolute;
	top: 1rem;
	.icon {
		left: 1rem;
		margin: 1rem;
		position: absolute;
		top: 0;
		width: 2rem;
		z-index: 1001;
	}
	&.open {
		display: none;
	}
}



.navbar {
	align-items: center;
	color: #f3dfc1 !important;
	display: flex;
	justify-content: center;
	font-family: "newpaper", serif;
	font-size: 1.4rem;
	font-variant: small-caps;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1001;

	@include mobile {
		display: none;
	}

	&.open {
		bottom: 1rem;
		top: unset;
	}

	.block-sections {
		display: flex;
		width: 100%;
		padding-left: 1rem;
		padding-right: 1rem;
		padding-top: 0.5rem;
		margin: auto;
		@include mobile {
			position: absolute;
			transform: translate(-50%,-100%);
			left: 50%;
			width: 100%;
			display: block;
		}
		.page {
			width: 100%;
			margin: auto;
			text-align: center;
		}
	}
	.sections {
		color: #f3dfc1;
		width: 20%;
		margin: auto;
		text-align: center;
		text-decoration: none;
		font-family: 'IM Fell English SC', serif;
		@include mobile {
			position: relative;
			display: block;
			width: 100%;
			font-size: 1.8rem;
			padding: 0.9rem;
			z-index: 4;
		}
	}
}


.open {
	display: block;
	position: absolute;
	bottom: 1rem;
}

.section {
	background: #f3dfc1;
	position: relative;
}


.header {
	.background-wrapper {
		position: relative;
		height: 100vh;
		&:before {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			background: linear-gradient(180deg, rgba(20,21,28,0) 0%, rgba(26,27,29,1) 100%);
		}
		.top-page {
			background: #f3dfc1;
		}
		.title-home, .title-menu {
			color: #f3dfc1;
			font-family: "handwriter", serif;
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-50%,-50%);
			z-index: 1000;
			@include mobile {
				top: 45%;
				font-size: 5rem;
				width: 100%;
			}
			h1 {
				font-size: 8rem;
				margin-bottom: 0;
				text-align: center;
				line-height: 90%;
				@include minidesktop {
					font-size: 6rem;
				}
				@include mobile {
					font-size: 5rem;
					line-height: 90%;
					margin: auto;
					margin-bottom: 3.2rem;
				}

				br {
					display: none;
					@include mobile {
						display: block;
					}
				}
			}

			h2 {
				font-family: "handwriter2", serif;
				font-size: 2.7rem;
				margin-top: 4.5rem;
				text-align: center;
				@include minidesktop {
					margin-top: 4rem;
				}
				@include mobile {
					font-size: 2rem;
					margin-top: 0;
				}
			}
		}

        .title-menu {
            display: none;
        }
		.text-top-page-mobile {
			display: none;
			@include mobile {
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translate(-50%,-15%);
				color: #f3dfc1;
				z-index: 1;
				margin: auto;
				display: block;
				line-height: 150%;
				text-align: center;
				width: 90%;
				font-family: "old";
				a {
					text-decoration: underline;
				}
			}
		}
		&.open {
            .title-home {
                display: none;
            }
			.title-menu {
                display: block;
				top: 10%;

				h1 {
					font-size: 4rem;
					margin-bottom: 2.6rem;
					margin-top: 5rem;
				}
				h2 {
					font-size: 1.5rem;
				}
			}
			.text-top-page-mobile {
				display: none !important;
			}
		}
	}
}

.background-wrapper {
	position: unset !important;
	margin: auto;
	display: block;
	width: 100%;
	height: 100vh;
	.background {
		content: "";
		z-index: 0;
		background-position: 100% 50%;
		background-size: cover;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100%;
		@include mobile {
			background-position: 50% 50%;
		}
	}
	&:after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,0.7);
		min-height: 100vh !important;
		height: 100vh !important;
		z-index: 0 !important;
		@include minidesktop {
			background-color: rgba(0,0,0,0.7);
		}
		@include mobile {
			background-color: rgba(0,0,0,0.65);
		}
	}
	&.open::after {
		background: rgba(0, 0, 0, 0.8) !important;
		
	}
}


.back-footer {
	position: relative;
	display: black;
	width: 100%;
	background: #f3dfc1;
}

#footer {
	align-items: center;
	display: flex;
	font-family: "old";
	justify-content: center;
	padding: 1rem 0;
	.desktop {
		display: block;
	}
	@include mobile {
		background: #f3dfc1;
		display: flex;
		flex-direction: column;
		padding-top: 3rem;
		width: 100%;
		margin:auto;
		padding-left: 1rem;
		padding-right: 1rem;
		padding-bottom: 0.5rem;
		.left-column {
			order: 1;
			@include mobile {
				border-top: 1px solid black;
				width: 100%;
				padding-top: 1rem;
			}
		}
		.right-column {
			order: 2;
		}
		.middle-column {
			order: 3;
			@include mobile  {
				width: 100%;
			}
		}
	}
	.mid-footer {
		width: 60%;
		margin: auto;
		@include mobile {
			width: 90%;
		}
	}
	.left-column {
		margin-left: 2rem;
		margin-right: auto;
		width: 20%;
		@include mobile {
			margin: auto;
			width: 90%;
		}
		a {
			text-align: left;
			line-height: 40px;
			font-variant: small-caps !important;
			@include mobile {
				text-align: center;
				font-size: 1rem;
			}
		}
	}
	.social-medias {
		display: flex;
		margin: auto;
		width: 6rem;
		margin-top: 1rem;
		margin-bottom: 0;
		z-index: 2;
		@include mobile {
			margin-bottom: 1rem;
		}
		.link {
			margin: auto;
		}
		.instagram-icon {
			width: 2.4rem;
			margin: auto;
		}
		.facebook-icon {
			width: 2rem;
			margin: auto;
		}
	}
	.infos {
		text-align: center;
		line-height: 200%;
		display: block;
		padding-bottom: 1rem;
		border-top: 1px solid black;
		border-bottom: 1px solid black;
		font-size: 0.95rem;
		margin-bottom: 1.5rem;
		padding-top: 1rem;
		@include desktop {
			display: none;
		}
		.icon-infos {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: auto;
			margin-right: 0;
		}
		span {
			margin: auto;
			margin-left: 0;
		}
		.adress {
			display: flex;
			margin: auto;
			.icon-maps {
				width: 1.1rem;
				margin-right: 0.3rem;
			}
		}
		.way {
			display: flex;
			text-align: center;
			margin: auto;
			margin-top: 0.25rem;
			margin-bottom: 0.2rem;
			.icon-metro {
				width: 1.5rem;
				margin-right: 0.3rem;
			}
			span {
				margin: auto;
				margin-left: 0;
			}
		}
		.schedules {
			display: flex;
			text-align: center;
			margin: auto;
			.icon-clocks {
				width: 1.5rem;
				margin-right: 0.3rem;
			}
		}
	}
	.terms {
		text-align: center;
	}
	.right-column {
		margin-left: auto;
		margin-right: 2rem;
		width: 20%;
		@include mobile {
			margin: auto;
			width: 90%;
		}
		a {
			text-align: right;
			line-height: 40px;
			font-variant: small-caps !important;
			@include mobile {
				text-align: center;
				font-size: 1rem;
			}
		}
	}
	.links a {
		color: black;
		display: block;
		font-variant: normal;
		margin: auto;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}

		&.mobile {
			display: none;

			@include mobile {
				display: block;
			}
		}
	}
	#bot-footer {
		text-align: center;
		margin: auto;
		margin-top: 0.5rem;
		color: black !important;
		font-variant: normal;
		font-size: 0.9rem;
		p {
			margin: 0;
		}
		@include mobile {
			padding-bottom: 0.5rem;
		}
	}
	.by {
		width: 100%;
		margin: auto;
		margin-bottom: 1rem;
		display: flex;
		width: 30%;
		align-content: center;
		align-items: center;
		@include minidesktop {
			width: 40%;
		}
		@include mobile {
			width: 100%;
		}
		.product-by {
			font-size: 0.9rem;
			padding-right: 0;
			padding-top: 0.2rem;
			@include mobile {
				font-size: 0.85rem;
				padding-top: 0;
				padding-right: 0.2rem;
			}
		}
		.signature {
			width: 10rem;
			margin: auto;
			display: block;
			margin-top: 0.5rem;
			@include minidesktop {
				width: 10rem;
				padding-top: 0.2rem;
			}
			@include mobile {
				width: 50%;
				margin-top: 0.3rem;
				padding-top: 0;
			}

		}
	}
}

a {
	text-decoration: none;
	color: inherit;
}

[data-page="club"] {
	.background {
		background: url("../images/background/club.jpg") 100% 50%;
		background-size: cover;

		@include mobile {
			background-position: 45% 50%;
		}
	}
}

[data-page="founder"] {
	.background {
		background: url("../images/background/portraitnezenlair.jpg") 100% 30%;
		background-size: cover;

		@include mobile {
			background-position: 45% 0%;
		}
	}
}

[data-page="publications"] {
	.background {
		background: url("../images/background/sam_the.jpg") 100% 100%;
		background-size: cover;

		@include mobile {
			background-position: 68% 50%;
		}
	}
}

[data-page="gallery"] {
	.background {
		background: url("../images/background/gallery.jpg") 100% 25%;
		background-size: cover;

		@include mobile {
			background-position: 45% 50%;
		}
	}
}

[data-page="poetry"] {
	.background {
		background: url("../images/background/fantinlatour.jpg") 100% 25%;
		background-size: cover;

		@include mobile {
			background-position: 3% 0%;
		}
	}
}

.page-title {
	.top-page {
		display: flex;
		padding: 1rem;
		border-bottom: 2px solid black;
		background: #f3dfc1;
		z-index: 1;
		position: relative;
		.top-page {
			display: flex;
			width: 100%;
		}
		.title-section {
			display: flex;
			margin: auto;
			width: 60%;
			@include mobile {
				width: 100%;
			}
		}
		.title-page {
			font-family: 'IM Fell English SC', serif;
			font-size: 3.7rem;
			text-align: center;
			margin-top: 1rem;
			@include minidesktop {
				font-size: 3.2rem;
			}
			@include mobile {
				display: block;
				font-family: 'IM Fell English SC', serif;
				//font-size: 2.1rem;
				font-size: 2.1rem;
				text-align: center;
				margin: auto;
			}
            & .title__desktop {
                display: block;
                @include mobile {
                    display: none;
                }
            }
            & .title__mobile {
                display: none;
                @include mobile {
                    display: block;
                }
            }
		}
		.subtitle-page {
			font-size: 0.8rem;
			line-height: 17px;
			font-variant: small-caps;
			text-decoration: underline;
			font-family: "newpaper-italic";
			width: 90%;
			margin: auto;
			@include mobile {
				font-size: 0.8rem;
				width: 100%;
			}
		}
		.left, .right, .title-page, .schedules {
			display: block;
			margin: auto;
			text-align: center;
		}
		.left {
			margin-right: 1.5rem;
			margin-left: auto;
			margin-top: 0;
			@include minidesktop {
				width: 20%;
			}
			@include mobile {
				display: none;
			}
		}
		.right {
			margin-right: auto;
			margin-left: 1.5rem;
			margin-top: 0;
			@include minidesktop {
				width: 20%;
			}
			@include mobile {
				display: none;
			}
		}
		.schedules {
			border: thick double black;
			font-size: 0.9rem;
			max-width: 15rem;
			width: 15rem;
			font-family: "newpaper";
			text-align: left;
			padding: 0.5rem;
			line-height: 23px;
			width: 15%;
			@include mobile {
				display: none;
			}
		}
		.schedules-left {
			margin-left: 0;
			margin-right: 1rem;
		}
		.schedules-right {
			margin-left: 1rem;
			margin-right: 0;
		}
		.icon-left, .icon-right {
			display: block;
			margin: auto;
			width: 7rem;
			@include minidesktop {
				width: 7rem;
			}
			@include mobile {
				display: none;
			}
		}
	}

	.date {
		align-items: center;
		background: #f3dfc1;
		border-bottom: 2px solid black;
		display: flex;
		font-family: "newpaper";
		font-variant: small-caps;
		height: 3rem;
		justify-content: center;
		position: relative;;
	}
}

.read-more {
	align-items: flex-start;
	display: flex;
	font-family: "IM Fell English SC", serif;
	font-style: italic;
	justify-content: space-around;
	line-height: 1;
	margin: 1rem auto;
	text-decoration: underline;
	width: auto;
	align-items: center;
	&::before {
		background: url("../images/handr2.png") no-repeat bottom;
		background-size: contain;
		content: "";
		display: block;
		height: 1.75rem;
		margin-right: 1rem;
		width: 3rem;
		@include minidesktop {
			width: 2.5rem;
		}
	}
	&::after {
		background: url("../images/handl2.png") no-repeat bottom;
		background-size: contain;
		content: "";
		display: block;
		height: 1.75rem;
		margin-left: 1rem;
		width: 3rem;
		@include minidesktop {
			width: 2.5rem;
		}
	}
}
p {
	line-height: normal;
}

.poets-list-wrapper {
	.title {
		font-family: 'old';
		font-size: 1.8rem;
		text-align: center;
		line-height: 100%;
		padding-bottom: 1rem;
		@include minidesktop {
			font-size: 1.5rem;
			margin-top: 0;
		}
		@include mobile {
			width: 80%;
			margin: 0 auto;
			line-height: 100%;
			padding-bottom: 0;
			font-size: 1.8rem;
			margin-top: 0;
		}
	}
	.subtitle {
		font-style: italic;
		text-align: center;
		color: #584746;
		@include minidesktop {
			padding-bottom: 1rem;
		}
		@include mobile {
			width: 70%;
			margin: 0 auto;
			line-height: 100%;
			padding-top: 0.5rem;
		}
	}

	.poets-list {
		display: block;
		font-variant: small-caps;
		line-height: 200%;
		list-style: none;
		margin: 0.5rem auto 2rem;
		padding-left: 0;
		@include mobile {
			line-height: 100%;
			margin: 1.5rem auto 2rem;
			padding-bottom: 0;
			width: 85%;
		}
		li {
			display: flex;
			align-items: center;
			margin-bottom: 0.6rem;
			@include mobile {
				padding-left: 1.5rem;
			}
			a {
				line-height: 100%;
				padding-bottom: 0.5rem;
				@include mobile {
					padding-bottom: 0;
				}
			}
		}
	}

	.poets-list--second-part {
		display: none;
	}

	.poets-list--francaise,
	.poets-list--monde {
		li:before {
			background: url('../images/hand_icon-right.png');
			background-size: cover;
			content: "";
			display: block;
			height: 1.6rem;
			margin-right: 0.5rem;
			width: 2.8rem;
			@include minidesktop {
				height: 1.2rem;
				width: 2rem;
			}
			@include mobile {
				height: 1.6rem;
				width: 2.8rem;
			}
		}
	}

	.poets-list--francaise {
		li {
			@include mobile {
				padding-left: 1.5rem;
			}
		}
	}


    .poets-list--monde:before {
        background-image: url('../images/globe.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        content: "";
        display: block;
        height: 10rem;
        margin-bottom: 1rem;
		@include minidesktop {
			display: none;
		}
		@include mobile {
			display: block;
			height: 10rem;
		}
    }

	.poets-list--enfants {
		li:before {
			background: url('../images/book2.png');
			background-size: cover;
			content: "";
			display: block;
			height: 1.6rem;
			margin-right: 0.5rem;
			width: 3rem;
		}
	}

	.poets-list--enfants:before {
        background-image: url('../images/bag.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        content: "";
        display: block;
        height: 6rem;
        margin-bottom: 1rem;
		@include minidesktop {
			display: none;
		}
		@include mobile {
			display: none;
		}
    }

	.poets-list--resistance {
		li:before {
			background: url('../images/helmet.png');
			background-size: cover;
			content: "";
			display: block;
			height: 1.6rem;
			margin-right: 0.5rem;
			width: 1rem;
			@include mobile {
				height: 2rem;
				width: 1.5rem;
			}
		}
	}

	.poets-list--resistance:before {
        background-image: url('../images/medal.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        content: "";
        display: block;
        height: 6rem;
        margin-bottom: 1rem;
		@include minidesktop {
			display: none;
		}
		@include mobile {
			display: block;
			height: 7rem;
		}
    }

	.see-more-button {
		display: block;
		margin: 2rem auto 0;
		color: black;
		.more {
			display: block;
		}

		.less {
			display: none;
		}

		&.show-less {
			.more {
				display: none;
			}

			.less {
				display: block;
			}
		}
	}
}


h1 {
	margin-top: 0;
	margin-bottom: 0;
}