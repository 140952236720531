body[data-page="poem"]{
    .title-page {
        width: 50%;
        @include mobile {
            width: 100%;
            font-size: 2.3rem;
        }
    }
    &:after {
        background-color: rgba(0,0,0,0.8) !important;
    }
    .header {
        .title-home {
            top: 45%;
            @include mobile {
                top: 45%;
                left: 50%;
                width: 95%;
                margin: auto;
            }
        }
    }
    .title-poet {
        font-size: 7.7rem;
        line-height: 85%;
        @include mobile {
            font-size: 5.5rem !important;
            line-height: 85%;
        }
    }
    .title-home {
        top: 38%;
        h1 {
            letter-spacing: -2px;
            @include mobile {
                margin-bottom: 0 !important;
                margin-top: 0 !important;
                line-height: 105% !important;
                font-size: 4.8rem !important;
            }
        }
    }
    .background {
        @include mobile {
            background-position: 50% 100%;
        }
    }
    .background-wrapper {
        &:after {
            background-color: rgba(0,0,0,0.8) !important;
        }
        .index-poem {
          h1 {
              font-size: 3.7rem !important;
          }
        }
    }
    .poets-list-wrapper {
        padding: 0 1rem;
        .title {
            font-variant: small-caps;
        }

        .poets-list {
            width: 75%;
            @include minidesktop {
                width: 95%;
            }
            @include mobile {
                width: 85%;
            }
        }
    }
    .text-top-page {
        display: none;
    }
    .text-top-page-mobile {
        display: none !important;
    }
}

.background[data-background-name="yvan-goll"] {
    background-position: 0% 15%;
}


.header .index-poem::after {
    @include mobile {
        background-color: rgba(0,0,0,0.6) !important;
    }
}

#poem {
    background: #f3dfc1;
    width: 100%;
    display: flex;
    z-index: 1;
    position: relative;
    @include mobile {
        display: block;
    }
    .poem-background {
        background: #f3dfc1;
        width: 100%;
        display: flex;

        @include mobile {
            flex-direction: column;

            .col-center {
                margin-top: 2rem;
                order: 1;
            }
            .col-left {
                order: 2;
            }
            .col-right {
                order: 3;
            }
        }
    }
    .col-left {
        width: 20%;
        border-right: 1px solid black;
        padding-right: 1rem;
        margin-top: 2rem;
        @include minidesktop {
            width: 22%;
        }
        @include mobile {
            margin-top: 0;
            width: 100%;
            padding-top: 2rem;
            border: none;
            padding-right: 0;
            padding-bottom: 2rem;
        }
        .img-sep {
            width: 80%;
            margin: 3rem auto;
            display: block;
            transform: rotate(360deg);
            margin-top: 0rem;
        }
        .biography {
            .title-biography {
                font-family: "old";
                font-size: 2rem;
                font-variant: small-caps;
                text-align: center;
                margin-top: -1rem;
                @include minidesktop {
                    font-size: 2rem;
                }
                @include mobile {
                    font-size: 2rem;
                    padding-top: 0rem;
                }
            }
            .citation {
                font-family: "newpaper-italic";
                text-align: center;
                padding-left: 1rem;
                color: #584746;
                width: 90%;
                margin: auto;
                margin-bottom: 2rem;
                @include mobile {
                    margin-bottom: 1rem;
                    padding-left: 0;
                }
            }
            .text-biography {
                padding: 1rem;
                padding-left: 2rem;
                padding-top: 0;
                margin-top: -1.5rem;
                line-height: 150%;
                text-align: justify;
                font-size: 1rem;
                padding-right: 1rem;
                color: #584746;
                @include minidesktop {
                    padding: 0;
                    padding-left: 1rem;
                    font-size: 0.95rem;
                }
                @include mobile {
                    margin-top: 1rem;
                    font-size: 1rem;
                    padding-right: 1rem;
                }
                i {
                    font-family: "handwriter";
                    font-size: 1.5rem;
                    font-size: 1.3rem;
                    line-height: 0%;
                }
                a {
                    text-decoration: underline;
                }
            }
            .source {
                font-size: 1.3rem;
                font-family: "handwriter";
                text-align: right;
                padding-right: 1rem;
                line-height: 115%;
                width: 80%;
                margin-right: 0;
                margin-left: auto;
                margin-top: 1rem;
            }
            .img {
                width: 60%;
                margin: auto;
                display: block;
                margin-top: 0;
                @include mobile {
                    width: 70%;
                    display: block;
                    margin: auto;
                    margin-top: 1rem;
                }
            }
        }
    }
    .col-center {
        width: 60%;
        position: relative;
        @include minidesktop {
            width: 56%
        }
        @include mobile {
            width: 100%;
        }
        .title-poem {
            text-align: center;
            font-size: 5.5rem;
            padding: 1rem;
            font-family: "handwriter2";
            line-height: 70%;
            width: 70%;
            margin: auto;
            margin-bottom: 2rem;
            margin-top: 2rem;
            line-height: 60%;
            @include minidesktop {
                font-size: 4.5rem;
            }
            @include mobile {
                font-size: 3.5rem;
                padding: 0;
                margin-top: 0;
                line-height: 70%;
                padding-top: 1rem;
                width: 90%;
            }
        }
        .infos {
            text-align: right;
            width: 80%;
            margin-top: 2rem;
            @include minidesktop {
                width: 95%;
            }
            @include mobile {
                width: 90%;
            }
            .date-poem {
                font-family: "handwriter2";
                font-size: 2rem;
            }
            .recueil {
                font-family: "newpaper-italic";
            }
        }
        .poets-navigation {
            width: 90%;
            margin: auto;
            margin-top: 4rem;
            display: flex;
            align-items: center;
            @include mobile {
                display: none;
            }
            .back-poem {
                visibility: visible;
            }
            .ornement {
                width: 12rem;
                margin-top: 2rem;
                @include minidesktop {
                    width: 8rem;
                }
                @include mobile {
                    width: 7rem;
                }
            }
        }
        .next-poets {
            color: black;
            @include mobile {
                padding-top: 2rem;
            }
        }
        .mobile {
            display: none;
            @include mobile {
                display: flex !important;
                a {
                    width: 35%;
                    color: black;
                }
                .back-poem {
                    width: 50%;
                    padding: 1rem;
                    display: none;
                }
            }
        }
    }
    .col-right {
        width: 20%;
        border-left: 1px solid black;
        padding-left: 1rem;
        margin-top: 2rem;
        @include minidesktop {
            width: 22%;
        }
        @include mobile {
            width: 100%;
            border: none;
            padding-left: 0;
            margin-top: 0;
        }
        .img-sep {
            width: 80%;
            margin: 3rem auto;
            display: block;
            transform: rotate(360deg);
            margin-top: 3rem;
        }
    }
}

@mixin poem-one-col {
    column-count: 1;
    column-gap: 0;
    line-height: 180%;
    margin: auto;
    width: 33%;
    padding-left: 3rem;
    @include minidesktop {
        padding-left: 1rem;
    }
    @include mobile {
        width: 80%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@mixin poem-two-col {
    column-count: 2;
    column-gap: 10%;
    line-height: 180%;
    margin: auto;
    width: 65%;
    padding-left: 2rem;
    @include minidesktop {
        width: 80%;
        padding-left: 0;
    }
    @include mobile {
        width: 80%;
        column-count: 1;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@mixin poem-three-col {
    column-count: 3;
    column-gap: 10%;
    line-height: 180%;
    margin: auto;
    width: 90%;
    padding-left: 5%;
    @include mobile {
        width: 80%;
        column-count: 1;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}




.poem {

    i {
        line-height: 100%;
    }
}

#poem .poem {
    @include poem-one-col;
}

#poem[data-poem-id="2"] .poem,
#poem[data-poem-id="3"] .poem,
#poem[data-poem-id="7"] .poem,
#poem[data-poem-id="8"] .poem,
#poem[data-poem-id="10"] .poem,
#poem[data-poem-id="11"] .poem,
#poem[data-poem-id="20"] .poem,
#poem[data-poem-id="22"] .poem,
#poem[data-poem-id="23"] .poem,
#poem[data-poem-id="24"] .poem,
#poem[data-poem-id="27"] .poem,
#poem[data-poem-id="28"] .poem,
#poem[data-poem-id="29"] .poem,
#poem[data-poem-id="32"] .poem,
#poem[data-poem-id="33"] .poem,
#poem[data-poem-id="34"] .poem,
#poem[data-poem-id="35"] .poem,
#poem[data-poem-id="37"] .poem,
#poem[data-poem-id="38"] .poem,
#poem[data-poem-id="39"] .poem,
#poem[data-poem-id="41"] .poem,
#poem[data-poem-id="42"] .poem,
#poem[data-poem-id="43"] .poem,
#poem[data-poem-id="45"] .poem,
#poem[data-poem-id="47"] .poem,
#poem[data-poem-id="49"] .poem,
#poem[data-poem-id="50"] .poem,
#poem[data-poem-id="51"] .poem,
#poem[data-poem-id="52"] .poem,
#poem[data-poem-id="53"] .poem,
#poem[data-poem-id="54"] .poem,
#poem[data-poem-id="55"] .poem,
#poem[data-poem-id="56"] .poem,
#poem[data-poem-id="67"] .poem,
#poem[data-poem-id="70"] .poem,
#poem[data-poem-id="75"] .poem,
#poem[data-poem-id="79"] .poem,
#poem[data-poem-id="83"] .poem,
#poem[data-poem-id="88"] .poem,
#poem[data-poem-id="90"] .poem,
#poem[data-poem-id="92"] .poem,
#poem[data-poem-id="94"] .poem,
#poem[data-poem-id="98"] .poem,
#poem[data-poem-id="99"] .poem,
#poem[data-poem-id="104"] .poem,
#poem[data-poem-id="105"] .poem,
#poem[data-poem-id="109"] .poem,
#poem[data-poem-id="110"] .poem {
    @include poem-one-col;
}


#poem[data-poem-id="4"] .poem,
#poem[data-poem-id="6"] .poem,
#poem[data-poem-id="14"] .poem,
#poem[data-poem-id="15"] .poem,
#poem[data-poem-id="16"] .poem,
#poem[data-poem-id="18"] .poem,
#poem[data-poem-id="19"] .poem,
#poem[data-poem-id="21"] .poem,
#poem[data-poem-id="25"] .poem,
#poem[data-poem-id="26"] .poem,
#poem[data-poem-id="31"] .poem,
#poem[data-poem-id="36"] .poem,
#poem[data-poem-id="40"] .poem,
#poem[data-poem-id="46"] .poem,
#poem[data-poem-id="48"] .poem,
#poem[data-poem-id="59"] .poem,
#poem[data-poem-id="61"] .poem,
#poem[data-poem-id="62"] .poem,
#poem[data-poem-id="63"] .poem,
#poem[data-poem-id="64"] .poem,
#poem[data-poem-id="65"] .poem,
#poem[data-poem-id="66"] .poem,
#poem[data-poem-id="68"] .poem,
#poem[data-poem-id="69"] .poem,
#poem[data-poem-id="74"] .poem,
#poem[data-poem-id="78"] .poem,
#poem[data-poem-id="84"] .poem,
#poem[data-poem-id="85"] .poem,
#poem[data-poem-id="86"] .poem,
#poem[data-poem-id="87"] .poem,
#poem[data-poem-id="89"] .poem,
#poem[data-poem-id="93"] .poem,
#poem[data-poem-id="97"] .poem,
#poem[data-poem-id="102"] .poem,
#poem[data-poem-id="103"] .poem,
#poem[data-poem-id="107"] .poem,
#poem[data-poem-id="111"] .poem,
#poem[data-poem-id="115"] .poem,
#poem[data-poem-id="116"] .poem,
#poem[data-poem-id="117"] .poem   {
    @include poem-two-col;
}

#poem[data-poem-id="1"] .poem,
#poem[data-poem-id="5"] .poem,
#poem[data-poem-id="13"] .poem,
#poem[data-poem-id="60"] .poem ,
#poem[data-poem-id="66"] .poem,
#poem[data-poem-id="82"] .poem,
#poem[data-poem-id="108"] .poem,
#poem[data-poem-id="113"] .poem {
    @include poem-three-col;
    @include minidesktop {
        @include poem-two-col;
    }
}

.img-separator {
    margin-bottom: 0;
    margin-top: 2rem;
}
